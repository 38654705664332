import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  Inject,
  ViewContainerRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RoutePartsService, LayoutService } from '@cuepid/egret';
import { filter } from 'rxjs/operators';
import { WINDOW } from '@cuepid/core';
import { DOCUMENT } from '@angular/common';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Cuepid';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private layoutService: LayoutService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    analytics: Angulartics2GoogleTagManager,
    public viewContainerRef: ViewContainerRef
  ) {
    analytics.startTracking();
    this.setViewHeightProperty();
  }

  @HostListener('window:resize')
  onViewResize() {
    this.setViewHeightProperty();
  }

  ngOnInit() {
    this.changePageTitle();
    this.layoutService.applyMatTheme();
  }

  ngAfterViewInit() {}

  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (routeParts.length === 0) {
          return this.title.setTitle(this.appTitle);
        }

        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => `${partA} > ${partI}`);
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }

  private setViewHeightProperty() {
    const displayHeight = this.window.innerHeight;
    this.document.documentElement.style.setProperty(
      '--vh',
      `${displayHeight / 100}px`
    );
  }
}
