import { Injectable } from '@angular/core';
import { Campaign } from '../models/campaign';
import { Medium } from '../models/medium';
import { InstagramRewardCalculator } from '../calculators/instagram-reward.calculator';
import Requirement = Campaign.Requirement;

@Injectable({providedIn: 'root'})
export class RewardCalculatorFactory {
  create(requirement: Requirement) {
    if (requirement.medium_id === Medium.INSTAGRAM.id) {
      return new InstagramRewardCalculator();
    } else {
      throw new Error('Could not found medium.');
    }
  }
}
