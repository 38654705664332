<div>
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>テンプレートから選ぶ</mat-label>
    <mat-select [formControl]="templateControl">
      <mat-option value="">-</mat-option>
      <mat-option *ngFor="let template of templates" [value]="template">{{ template.name }}</mat-option>
    </mat-select>
    <mat-hint>テンプレートをお使いになる際はこちらからご選択ください。</mat-hint>
  </mat-form-field>
</div>
<div class="mt-24 mb-12">
  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
    <mat-label>メッセージ本文</mat-label>
    <textarea name="body" [formControl]="messageBodyControl" [placeholder]="placeholder" matInput></textarea>
    <mat-hint>3000文字以内でご入力ください。</mat-hint>
    <mat-error *ngIf="messageBodyControl.hasError('required')">
      メッセージ本文は必ず入力してください。
    </mat-error>
    <mat-error *ngIf="messageBodyControl.hasError('maxlength')">
      メッセージ本文は3000文字以内で入力してください。
    </mat-error>
  </mat-form-field>
</div>
<div>
  <button mat-button (click)="openInputTitleDialog()">上記メッセージをテンプレート化する</button>
</div>
