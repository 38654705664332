import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { CustomValidators } from 'ngx-custom-validators';
import { Campaign, GoogleMapService, Prefecture } from '@cuepid/core';
import { format, isBefore, isSameMinute, parse } from 'date-fns';
import { DateValidator } from '@cuepid/enterprise/core/validators/date.validator';
import { LoadIndicatorComponent } from '@cuepid/enterprise/shared/components/load-indicator/load-indicator.component';
import { ToastrService } from 'ngx-toastr';

const triggerDefinitions = [
  state('open', style({opacity: 1, display: 'block'})),
  state('close', style({opacity: 0, display: 'none'})),
  transition('open => close', [
    animate('0.3s', style({opacity: 0})),
  ]),
  transition('close => open', [
    animate('0.3s', style({opacity: 1})),
  ]),
];

@Component({
  selector: 'app-input-campaign-detail',
  templateUrl: './input-campaign-detail.component.html',
  styleUrls: ['./input-campaign-detail.component.scss'],
  animations: [
    trigger('event', triggerDefinitions),
    trigger('sampling', triggerDefinitions),
  ],
})
export class InputCampaignDetailComponent implements OnInit {

  @Input() readonly = false;

  readonly prefectures = Prefecture.all();
  readonly types = Campaign.Type;

  readonly hints = {
    type: '商品提供：商品やサービスを送付し試していただく場合\nイベント参加：特定の場所でサービスや商品を体験していただく場合',
    event: {
      period: '招待されるイベントの開催期間をご入力ください。',
      time: '予め時間が決まっている場合はご入力ください。個別にご案内されたい場合は「③応募要件」> 投稿の手順・流れ > その他記載事項 にてその旨の記載をいただき、詳細は当選後各インフルエンサーとメッセージ機能を活用して個別にご案内ください。',
      companion: '何名まで可能か、その際の交通費はどうするのか、など詳細については、③「応募要件」> 投稿の手順・流れ > その他記載事項 にて詳細をご案内ください。',
      transportation: '上限金額、お支払いフローについて、など詳細については、③「応募要件」> 投稿の手順・流れ > その他記載事項 にて詳細をご案内ください。',
    },
    sampling: {
      deliveryDays: 'インフルエンサー採用から、商品配送までにかかる目安の期日を入力ください。\n余裕をもったスケジュール設定をお願いいたします。',
    },
  };

  form: FormGroup;

  constructor(fb: FormBuilder, private googleMapService: GoogleMapService, private dialog: MatDialog, private toastrService: ToastrService) {

    const startDate = fb.control('', [Validators.required, CustomValidators.date]);
    const endDate = fb.control('', [Validators.required, CustomValidators.date]);
    startDate.setValidators(DateValidator.isValidPeriod(startDate, endDate));
    startDate.valueChanges.subscribe(() => endDate.updateValueAndValidity({emitEvent: false}));
    endDate.setValidators(DateValidator.isValidPeriod(startDate, endDate));
    endDate.valueChanges.subscribe(() => startDate.updateValueAndValidity({emitEvent: false}));

    const startTime = fb.control('');
    const endTime = fb.control('');
    startTime.setValidators([this.isRequiredCombination(endTime), this.isValidTimePeriod(startTime, endTime)]);
    startTime.valueChanges.subscribe(() => endTime.updateValueAndValidity({emitEvent: false}));
    endTime.setValidators([this.isRequiredCombination(startTime), this.isValidTimePeriod(startTime, endTime)]);
    endTime.valueChanges.subscribe(() => startTime.updateValueAndValidity({emitEvent: false}));

    const event = fb.group({
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      postcode: ['', [Validators.required, Validators.pattern('\\d{3}-\\d{4}')]],
      prefecture: ['', [Validators.required, CustomValidators.range([1, 47])]],
      address: ['', [Validators.required]],
      with_companion: [false, [Validators.required]],
      include_transportation: [false, [Validators.required]],
    });
    event.disable();

    const sampling = fb.group({
      delivery_days: ['', [Validators.required, CustomValidators.digits]],
    });

    this.form = fb.group({
      type: [Campaign.Type.SAMPLING, []],
      event,
      sampling,
    });

    this.form.get('type')?.valueChanges.subscribe(type => {

      if (!(type instanceof Campaign.Type)) {
        const value = (type.code === Campaign.Type.EVENT.code) ? Campaign.Type.EVENT : Campaign.Type.SAMPLING;
        this.form.get('type')?.patchValue(value);
      }

      if (type === Campaign.Type.EVENT) {
        // タイミングによってビューが更新されないためケースが存在するためスレッドをずらす
        setTimeout(() => {
          event.enable({emitEvent: false});
          sampling.disable({emitEvent: false});
        });
        return;
      }

      sampling.enable({emitEvent: false});
      event.disable({emitEvent: false});
    });
  }

  get type() {
    return this.form.get('type')?.value;
  }

  ngOnInit() {
  }

  setFormattedDate(formControlName: string, value: unknown) {
    this.form.get(formControlName)?.setValue(format(value as Date, 'yyyy-MM-dd'));
  }

  searchAddress() {
    const eventForm = this.form.get('event') as FormGroup;
    if (eventForm.disabled) {
      return;
    }

    const {postcode, prefecture, address} = eventForm.controls;
    if (postcode.invalid) {
      return;
    }

    const dialogRef = this.dialog.open(LoadIndicatorComponent, {
      disableClose: true,
    });

    this.googleMapService.getAddress(postcode.value).subscribe(([result = null]) => {
      dialogRef.close();
      if (result === null) {
        return;
      }

      const components = result.address_components;
      components.shift(); // 郵便番号が消去される
      components.reverse();
      components.shift(); // 国名が消去される
      const prefectureName = components.shift()?.long_name;
      if (!prefectureName) {
        return;
      }
      prefecture.setValue(Prefecture.fromName(prefectureName)?.code);
      address.setValue(components.map((component: any) => component.long_name).join(''));
    }, () => {
      dialogRef.close();
      this.toastrService.error('入力された郵便番号は存在しません。郵便番号をご確認ください。');
    });
  }

  restoreData(data: {[key: string]: any}): void
  {
    if (data['event']) {
      for (const key of ['start_time', 'end_time']) {
        if (data['event'][key]) {
          const parts = data['event'][key].split(':');
          if (parts.length > 2) {
            parts.pop();
          }
          data['event'][key] = parts.join(':');
        }
      }
    }

    this.form.patchValue(data);
  }

  private isRequiredCombination(other: FormControl): ValidatorFn {
    return control => {
      control.markAsTouched({onlySelf: true});
      other.markAsTouched({onlySelf: true});

      if (control.value !== '' && other.value !== '') {
        return null;
      }

      if (control.value === '' && other.value === '') {
        return null;
      }

      if (control.value !== '') {
        return null;
      }

      return {combination: true};
    };
  }

  private isValidTimePeriod(from: FormControl, to: FormControl): ValidatorFn {
    return () => {
      if (!from.value || !to.value) {
        return null;
      }

      const fromDate = parse(from.value, 'HH:mm', 0);
      const toDate = parse(to.value, 'HH:mm', 0);
      if (isSameMinute(fromDate, toDate) || isBefore(fromDate, toDate)) {
        return null;
      }

      return {time_period: true};
    };
  }
}
