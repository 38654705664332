import { PercentPipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { AudienceGender } from '@cuepid/enterprise/core/models/instagram/audience-statistic';
import { ChartOptions } from 'chart.js';
import { Colors } from 'ng2-charts';

@Component({
  selector: 'app-instagram-audience-gender',
  templateUrl: './instagram-audience-gender.component.html',
  styleUrls: ['./instagram-audience-gender.component.scss'],
  providers: [PercentPipe],
})
export class InstagramAudienceGenderComponent implements OnChanges {
  @Input() audienceGender?: AudienceGender;
  readonly labels = ['男性', '女性', '不明'];
  readonly colors: Colors[] = [
    { backgroundColor: ['#6B629E', '#EF94B3', '#57B196'] },
  ];
  readonly options: ChartOptions = {
    tooltips: {
      callbacks: {
        label: (
          tooltipItem: Chart.ChartTooltipItem,
          data: Chart.ChartData
        ): string => {
          const index = tooltipItem.index as number;
          const label = data.labels?.[index];
          const value = data.datasets?.[0].data?.[index] as string;
          return `${label}: ${this.percentPipe.transform(value, '1.1')}`;
        },
      },
    },
  };

  male = 0;
  female = 0;
  unknown = 0;

  constructor(private readonly percentPipe: PercentPipe) {}

  ngOnChanges(): void {
    if (!this.audienceGender) {
      this.male = 0;
      this.female = 0;
      this.unknown = 0;
      return;
    }

    this.male = this.audienceGender.male / this.audienceGender.sum;
    this.female = this.audienceGender.female / this.audienceGender.sum;
    this.unknown = this.audienceGender.unknown / this.audienceGender.sum;
  }
}
