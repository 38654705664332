import { Routes } from '@angular/router';
import { ExternalRedirectGuard } from '@cuepid/core';
import { CheckMaintenanceModeGuard } from '@cuepid/enterprise/core/guards/check-maintenance-mode.guard';
import { SkipAuthGuard } from '@cuepid/enterprise/core/guards/skip-auth.guard';
import { MaintenanceStatusResolver } from '@cuepid/enterprise/core/resolvers/maintenance-status.resolver';
import { MaintenanceComponent } from '@cuepid/enterprise/pages/maintenance/maintenance.component';
import { AdminLayoutComponent } from '@cuepid/enterprise/shared/components/layouts/admin-layout/admin-layout.component';
import { IndexComponent } from './pages/index/index.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    component: IndexComponent,
    canActivate: [CheckMaintenanceModeGuard, SkipAuthGuard],
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./pages/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'campaign',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/campaign/campaign.module').then((m) => m.CampaignModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'profile',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'influencer',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/influencer/influencer.module').then(
        (m) => m.InfluencerModule
      ),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'message',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/direct-message/direct-message.module').then(
        (m) => m.DirectMessageModule
      ),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'offer',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/offer/offer.module').then((m) => m.OfferModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'config',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/config/config.module').then((m) => m.ConfigModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'information',
    loadChildren: () =>
      import('./pages/information/information.module').then(
        (m) => m.InformationModule
      ),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'plan',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/contract/contract.module').then((m) => m.ContractModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'support',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    resolve: {
      status: MaintenanceStatusResolver,
    },
  },
  {
    matcher: (segments) => {
      const [uri] = segments;
      if (!uri.path.startsWith('lp')) {
        return null;
      }
      return { consumed: segments };
    },
    component: ExternalRedirectGuard,
    canActivate: [ExternalRedirectGuard],
    data: {
      link: '/lp/',
    },
    children: [
      {
        path: ':any',
        component: ExternalRedirectGuard,
        canActivate: [ExternalRedirectGuard],
        data: {
          link: '/lp/',
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
