<div class="sidenav-hold">
  <div class="icon-menu mb-1" *ngIf="hasIconMenu">
    <!-- Icon menu separator -->
    <div class="mb-1 nav-item-sep">
      <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
      <span class="text-muted icon-menu-title">{{iconMenuTitle}}</span>
    </div>
    <!-- Icon menu items -->
    <div class="icon-menu-item" *ngFor="let item of items">
      <button *ngIf="!item.disabled && item.type === 'icon'" mat-raised-button [matTooltip]="item.tooltip" routerLink="/{{item.state}}"
        routerLinkActive="mat-bg-primary">
        <mat-icon>{{item.icon}}</mat-icon>
      </button>
    </div>
  </div>

  <ul appDropdown class="sidenav">
    <li *ngFor="let item of items" appDropdownLink routerLinkActive="open">
      <div class="nav-item-sep" *ngIf="item.type === 'separator'">
        <mat-divider></mat-divider>
        <span class="text-muted">{{item.name}}</span>
      </div>
      <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
        <a routerLink="/{{item.state}}" appDropdownToggle matRipple *ngIf="item.type === 'link'">
          <span [matBadgeHidden]="!item.badge" matBadge="&#8288;" matBadgeSize="small" matBadgeColor="warn"></span>
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name}}</span>
          <span fxFlex></span>
          <span *ngIf="item.count">
            <span *ngIf="(item.count | async) && item.count.value > 0" class="count-span">
              <ng-container *ngIf="item.count.value > 10; else normalCount">
                10<span class="plus-sign">+</span>
              </ng-container>
              <ng-template #normalCount>{{ item.count.value }}</ng-template>
            </span>
          </span>
        </a>
        <a [href]="item.state" appDropdownToggle matRipple *ngIf="item.type === 'extLink'" target="_blank" rel="noopener noreferrer">
          <span [matBadgeHidden]="!item.badge" matBadge="&#8288;" matBadgeSize="small" matBadgeColor="warn"></span>
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name}}</span>
          <span fxFlex></span>
        </a>

        <!-- DropDown -->
        <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple (click)="onDropDown(item)">
          <span [matBadgeHidden]="!item.badge" matBadge="&#8288;" matBadgeSize="small" matBadgeColor="warn"></span>
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name}}</span>
          <span fxFlex></span>
          <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
        </a>
        <!-- LEVEL 2 -->
        <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
          <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">

            <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" appDropdownToggle *ngIf="itemLvL2.type !== 'dropDown'"
              matRipple>
              <span class="item-name lvl2">{{itemLvL2.name}}</span>
              <span fxFlex></span>
              <span *ngIf="itemLvL2.count">
                <span *ngIf="(itemLvL2.count | async) && itemLvL2.count.value > 0" class="count-span">
                  <ng-container *ngIf="itemLvL2.count.value > 10; else normalCount">
                    10<span class="plus-sign">+</span>
                  </ng-container>
                  <ng-template #normalCount>{{ itemLvL2.count.value }}</ng-template>
                </span>
              </span>

            </a>

            <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
              <span class="item-name lvl2">{{itemLvL2.name}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <!-- LEVEL 3 -->
            <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
              <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}" egretDropdownToggle
                  matRipple>
                  <span class="item-name lvl3">{{itemLvL3.name}}</span>
                </a>
              </li>
            </ul>

          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
