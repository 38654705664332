import { FormControl, ValidatorFn } from '@angular/forms';
import { isBefore, isDate, isSameDay, parseISO } from 'date-fns';

export class DateValidator {
  static isValidPeriod(fromControl: FormControl, toControl: FormControl): ValidatorFn {
    return () => {
      if (!fromControl.value || !toControl.value) {
        return {};
      }

      const from = this.getDateValue(fromControl);
      const to = this.getDateValue(toControl);
      if (isSameDay(from, to) || isBefore(from, to)) {
        return null;
      }

      return {period: true};
    };
  }

  private static getDateValue(control: FormControl): Date {
    return isDate(control.value) ? control.value : parseISO(control.value);
  }
}
