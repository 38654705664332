import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadIndicatorInterceptor as Interceptor } from '@cuepid/core';
import { LoadIndicatorComponent } from '@cuepid/enterprise/shared/components/load-indicator/load-indicator.component';

@Injectable()
export class LoadIndicatorInterceptor extends Interceptor {

  constructor(private readonly dialog: MatDialog) {
    super();
  }

  protected getDialog(): MatDialogRef<any> {
    return this.dialog.open(LoadIndicatorComponent, {
      disableClose: true,
    });
  }
}
