import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL, Contract, ContractPlan } from '@cuepid/core';
import { plainToClass } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContractPlanService {
  showedUpgradeToast = false;
  contractUpdated = new Subject<void>();

  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apiBaseUrl: string
  ) {}

  all(): Observable<ContractPlan[]> {
    return this.http
      .get<ContractPlan[]>(`${this.apiBaseUrl}/contractPlan`)
      .pipe(map((data) => plainToClass(ContractPlan, data)));
  }

  getAppliedPlan(): Observable<ContractPlan> {
    return this.http
      .get<ContractPlan>(`${this.apiBaseUrl}/company/me/contract/now`)
      .pipe(map((data) => plainToClass(ContractPlan, data)));
  }

  changeContract(
    id: ContractPlan.ID,
    timing: Contract.StartTiming
  ): Observable<ContractPlan> {
    return this.http.post<ContractPlan>(
      `${this.apiBaseUrl}/company/me/contract`,
      {
        contract_plan_id: id,
        start_timing: timing,
      }
    );
  }
}
