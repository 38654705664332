import { Pipe, PipeTransform } from '@angular/core';
import { Sex } from '../constant/sex';

@Pipe({
  name: 'sex'
})
export class SexPipe implements PipeTransform {

  transform(code: number | undefined): string | null | undefined {
    if (!code) {
      return null;
    }
    return Sex.fromCode(code)?.name;
  }

}
