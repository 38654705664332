import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Influencer, PrefecturePipe, WINDOW } from '@cuepid/core';
import ShippingAddress = Influencer.ShippingAddress;
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { ShippingAddressService } from '@cuepid/enterprise/core/services/shipping-address.service';

interface DataType {
  influencerIds: number[];
  via: 'entry' | 'offer';
}

interface ShippingAddressRow {
  username: string;
  postcode: string;
  prefecture?: string;
  city: string;
  house_number: string;
  building?: string;
  name: string;
  name_kana: string;
  phone_number: string;
}

@Component({
  selector: 'app-show-shipping-address',
  templateUrl: './show-shipping-address.component.html',
  styleUrls: ['./show-shipping-address.component.scss'],
})
export class ShowShippingAddressComponent implements OnInit {
  address?: ShippingAddress;
  readonly columns = [
    'username',
    'name',
    'name_kana',
    'postcode',
    'prefecture',
    'city',
    'house_number',
    'building',
    'phone_number',
  ];
  addresses: ShippingAddressRow[] = [];

  constructor(
    private readonly shippingAddressService: ShippingAddressService,
    private readonly toastrService: ToastrService,
    private readonly prefecturePipe: PrefecturePipe,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(MAT_DIALOG_DATA) private readonly data: DataType
  ) {}

  ngOnInit(): void {
    const { influencerIds = [], via } = this.data;
    this.shippingAddressService
      .getList(influencerIds, via)
      .subscribe(
        (addresses) =>
          (this.addresses = addresses.map((address) =>
            this.convertRow(address)
          ))
      );
  }

  copyToClipboard(): void {
    const result = this.addresses
      .map((address) => {
        const rows = [
          `${address.username}`,
          `${address.name}`,
          `${address.name_kana}`,
          `${address.postcode}`,
          `${address.prefecture}`,
          `${address.city}${address.house_number} ${address.building}`,
          `${address.phone_number}`,
        ];
        return rows.join('\t');
      })
      .join('\r\n');
    from(this.window.navigator.clipboard.writeText(result)).subscribe(() =>
      this.toastrService.info('配送先情報をクリップボードにコピーしました。')
    );
  }

  private convertRow(address: ShippingAddress): ShippingAddressRow {
    return {
      username: address.influencer?.username || '-',
      city: address.city,
      building: address.building ?? '',
      house_number: address.house_number,
      phone_number: address.phone_number,
      name: address.name,
      name_kana: address.name_kana,
      postcode: address.postcode,
      prefecture: this.prefecturePipe.transform(address.prefecture) ?? '',
    };
  }
}
