<div>
  <form [formGroup]="form" [ngClass]="{readonly: readonly}">

    <div class="mb-32">
      <div class="mx-4 py-4">
        <label>キャンペーンタイプ</label>
        <span class="cursor-pointer" fxFlexAlign="center" [matTooltip]="hints.type" matTooltipPosition="right">
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>

      <ng-container *ngIf="!readonly">
        <mat-button-toggle-group formControlName="type">
          <mat-button-toggle [value]="types.SAMPLING">{{ types.SAMPLING.name }}</mat-button-toggle>
          <mat-button-toggle [value]="types.EVENT">{{ types.EVENT.name }}</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          {{form.get('type')!.value === types.SAMPLING ? types.SAMPLING.name : types.EVENT.name}}
        </div>
      </ng-container>
    </div>

    <div class="mb-32" [@sampling]="type === types.SAMPLING ? 'open' : 'close'" formGroupName="sampling">

      <div class="mb-8">
        <div class="mx-4 py-4">
          <label for="delivery_days">商品発送予定日</label>
          <span class="cursor-pointer" fxFlexAlign="center" [matTooltip]="hints.sampling.deliveryDays" matTooltipPosition="right">
          <mat-icon class="help">help</mat-icon>
        </span>
        </div>
        <ng-container *ngIf="!readonly">
          <mat-form-field appearance="outline">
            <input id="delivery_days" type="number" formControlName="delivery_days" min="1" matInput>
            <mat-error *ngIf="form.get('sampling.delivery_days')!.hasError('required')">
              配送予定日は必ずご入力ください。
            </mat-error>
            <mat-error *ngIf="form.get('sampling.delivery_days')!.hasError('digits')">
              配送予定日は数値でご入力ください。
            </mat-error>
          </mat-form-field>
          <span fxFlexAlign="center">日以内</span>
        </ng-container>
        <ng-container *ngIf="readonly">
          <div class="mx-16 py-4">
            {{form.get('sampling.delivery_days')!.value}}日以内
          </div>
        </ng-container>
      </div>

    </div>

    <div class="mb-32" [@event]="type === types.EVENT ? 'open' : 'close'" formGroupName="event">

      <div class="mb-8">
        <div class="mx-4 py-4">
          <label for="postcode">開催場所</label>
        </div>

        <div>
          <ng-container *ngIf="!readonly">
            <mat-form-field class="width-300px postcode" appearance="outline">
              <mat-label>郵便番号</mat-label>
              <div class="pr-4" matPrefix>〒</div>
              <input id="postcode" formControlName="postcode" placeholder="123-4567" (focusout)="searchAddress()" matInput>
              <mat-hint>半角英数字、ハイフンつなぎでご入力ください</mat-hint>
              <mat-error *ngIf="form.get('event.postcode')!.hasError('required')">
                郵便番号は必ずご入力ください
              </mat-error>
              <mat-error *ngIf="form.get('event.postcode')!.hasError('pattern')">
                郵便番号は半角英数字かつハイフンつなぎでご入力ください
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="readonly">
            <div class="mx-16 py-4">
              {{form.get('event.postcode')!.value}}
            </div>
          </ng-container>
        </div>
        <div>
          <ng-container *ngIf="!readonly">
            <mat-form-field appearance="outline">
              <mat-label>都道府県</mat-label>
              <mat-select formControlName="prefecture">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let prefecture of prefectures"
                            [value]="prefecture.code">{{ prefecture.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('event.prefecture')!.hasError('required')">
                都道府県は必ずご選択ください
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="readonly">
            <div class="mx-16 py-4">
              <ng-container *ngFor="let prefecture of prefectures">
                <span *ngIf="prefecture.code === form.get('event.prefecture')!.value">{{ prefecture.name }}</span>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div>
          <ng-container *ngIf="!readonly">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>住所</mat-label>
              <input formControlName="address" matInput>
              <mat-error *ngIf="form.get('event.address')!.hasError('required')">
                住所は必ずご入力ください
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="readonly">
            <div class="mx-16 py-4">
              {{form.get('event.address')!.value}}
            </div>
          </ng-container>
        </div>

      </div>

      <div class="mb-8">
        <div class="mx-4 py-4">
          <label for="start_date">開催期間</label>
          <span class="cursor-pointer" fxFlexAlign="center" [matTooltip]="hints.event.period" matTooltipPosition="right">
            <mat-icon class="help">help</mat-icon>
          </span>
        </div>

        <ng-container *ngIf="!readonly">
          <mat-form-field class="w-150" appearance="outline" (click)="eventStart.open()">
            <mat-label>開始</mat-label>
            <input id="start_date" formControlName="start_date" [matDatepicker]="eventStart"
                   (dateChange)="setFormattedDate('event.start_date', $event.value)" matInput readonly>
            <mat-datepicker-toggle matSuffix [for]="eventStart"></mat-datepicker-toggle>
            <mat-datepicker #eventStart></mat-datepicker>
            <mat-error *ngIf="form.hasError('required', 'event.start_date')">開始日は必ず入力してください</mat-error>
            <mat-error *ngIf="form.hasError('period', 'event.start_date')">開始日は終了日より前に設定してください</mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="readonly">
          <span class="mx-16">
            {{form.get('event.start_date')!.value}}
          </span>
        </ng-container>
        <span class="px-8">〜</span>
        <ng-container *ngIf="!readonly">
          <mat-form-field class="w-150" appearance="outline" (click)="eventEnd.open()">
            <mat-label>終了</mat-label>
            <input formControlName="end_date" [matDatepicker]="eventEnd"
                   (dateChange)="setFormattedDate('event.end_date', $event.value)" matInput readonly>
            <mat-datepicker-toggle matSuffix [for]="eventEnd"></mat-datepicker-toggle>
            <mat-datepicker #eventEnd></mat-datepicker>
            <mat-error *ngIf="form.hasError('required', 'event.end_date')">終了日は必ず入力してください</mat-error>
            <mat-error *ngIf="form.hasError('period', 'event.end_date')">終了日は開始日より後に設定してください</mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="readonly">
          <span class="mx-16">
            {{form.get('event.end_date')!.value}}
          </span>
        </ng-container>
      </div>

      <div class="mb-8">
        <div class="mx-4 py-4">
          <label for="start_time">開催時間<small> - 任意</small></label>
          <span class="cursor-pointer" fxFlexAlign="center" [matTooltip]="hints.event.time" matTooltipPosition="right">
            <mat-icon class="help">help</mat-icon>
          </span>
        </div>

        <ng-container *ngIf="!readonly">
          <mat-form-field class="w-150" appearance="outline" (click)="startTime.open()">
            <mat-label>開始</mat-label>
            <input id="start_time" formControlName="start_time" [ngxTimepicker]="startTime" [format]="24" matInput readonly>
            <ngx-material-timepicker-toggle matSuffix [for]="startTime"></ngx-material-timepicker-toggle>
            <ngx-material-timepicker #startTime></ngx-material-timepicker>
            <mat-error *ngIf="form.hasError('combination', 'event.start_time')">時間を指定する場合は開始・終了ともに指定してください</mat-error>
            <mat-error *ngIf="form.hasError('time_period', 'event.start_time')">終了時間より前に設定してください</mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="readonly">
          <span class="mx-16">
            {{form.get('event.start_time')!.value}}
          </span>
        </ng-container>
        <span class="px-8">〜</span>
        <ng-container *ngIf="!readonly">
          <mat-form-field class="w-150" appearance="outline" (click)="endTime.open()">
            <mat-label>終了</mat-label>
            <input formControlName="end_time" [ngxTimepicker]="endTime" [format]="24" matInput readonly>
            <ngx-material-timepicker-toggle matSuffix [for]="endTime"></ngx-material-timepicker-toggle>
            <ngx-material-timepicker #endTime></ngx-material-timepicker>
            <mat-error *ngIf="form.hasError('combination', 'event.end_time')">時間を指定する場合は開始・終了ともに指定してください</mat-error>
            <mat-error *ngIf="form.hasError('time_period', 'event.end_time')">開始時間より後に設定してください</mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="readonly">
           <span class="mx-16">
            {{form.get('event.end_time')!.value}}
          </span>
        </ng-container>
      </div>

      <div class="mb-8">
        <div class="mx-4 py-4">
          <ng-container *ngIf="!readonly">
            <mat-checkbox formControlName="with_companion">第三者の同伴を許可する</mat-checkbox>
          </ng-container>
          <ng-container *ngIf="readonly">
            <span>第三者の同伴を許可</span><span>{{form.get('event.with_companion')!.value ? 'する' : 'しない'}}</span>
          </ng-container>
          <span class="cursor-pointer" fxFlexAlign="center" [matTooltip]="hints.event.companion" matTooltipPosition="right">
            <mat-icon class="help">help</mat-icon>
          </span>
        </div>
      </div>

      <div class="mb-8">
        <div class="mx-4 py-4">
          <ng-container *ngIf="!readonly">
            <mat-checkbox formControlName="include_transportation">交通費を別途支給する</mat-checkbox>
          </ng-container>
          <ng-container *ngIf="readonly">
            <span>交通費を別途支給</span><span>{{form.get('event.include_transportation')!.value ? 'する' : 'しない'}}</span>
          </ng-container>
          <span class="cursor-pointer" fxFlexAlign="center" [matTooltip]="hints.event.transportation" matTooltipPosition="right">
            <mat-icon class="help">help</mat-icon>
          </span>
        </div>
      </div>

    </div>

  </form>
</div>
