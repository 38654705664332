import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_BASE_URL,
  Channel,
  Message,
  Paginator,
  WINDOW,
} from '@cuepid/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectMessageService {
  private readonly MESSAGE_CACHE_KEY = '___cuepid___enterprise_message_cache';

  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apiBaseUrl: string,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  archiveChannel(channel: Channel): Observable<Channel> {
    return this.http.post<Channel>(
      `${this.apiBaseUrl}/channel/${channel.id}/company/archive`,
      {}
    );
  }

  unarchiveChannel(channel: Channel): Observable<Channel> {
    return this.http.post<Channel>(
      `${this.apiBaseUrl}/channel/${channel.id}/company/unarchive`,
      {}
    );
  }

  getChannel(id: number): Observable<Channel> {
    return this.http.get<Channel>(`${this.apiBaseUrl}/channel/${id}`);
  }

  getChannels(
    page: number,
    perPage: number,
    archived: boolean
  ): Observable<Paginator<Channel>> {
    return this.http.get<Paginator<Channel>>(
      `${this.apiBaseUrl}/me/channel/influencer`,
      {
        params: {
          page: `${page}`,
          per_page: `${perPage}`,
          archived: archived ? 'true' : 'false',
        },
      }
    );
  }

  getMessages(
    channelId: number,
    latestMessageId: number | null = null
  ): Observable<Message[]> {
    const params: any = {
      for: 'company',
    };
    if (!!latestMessageId) {
      params.id = latestMessageId;
    }

    return this.http.get<Message[]>(
      `${this.apiBaseUrl}/channel/${channelId}/message`,
      {
        params,
      }
    );
  }

  getPreviousMessage(
    channelId: number,
    messageId: number
  ): Observable<Message[]> {
    return this.http.get<Message[]>(
      `${this.apiBaseUrl}/channel/${channelId}/message`,
      {
        params: {
          before: `${messageId}`,
        },
      }
    );
  }

  sendMessage(channel: Channel, message: string): Observable<Message> {
    return this.http.post<Message>(
      `${this.apiBaseUrl}/channel/${channel.id}/message`,
      {
        from: 'company',
        body: message,
      }
    );
  }

  sendMessageToMultiChannel(
    body: string,
    ...channels: Channel[]
  ): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/company/me/message/bulk`, {
      body,
      channels: channels.map((channel) => ({ id: channel.id })),
    });
  }

  sendAttachment(channel: Channel, files: FileList): Observable<Message> {
    const data = new FormData();
    data.append('from', 'company');
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (!file) {
        continue;
      }
      data.append('attachment[]', file);
    }

    return this.http.post<Message>(
      `${this.apiBaseUrl}/channel/${channel.id}/attachment`,
      data
    );
  }

  hasUnreadMessage(): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiBaseUrl}/company/me/message/exist-unread`
    );
  }

  storeMessageCache(channelId: number, value: string): void {
    const cache = this.getCache();
    cache[`${channelId}`] = value;
    this.window.localStorage.setItem(
      this.MESSAGE_CACHE_KEY,
      JSON.stringify(cache)
    );
  }

  getCachedMessage(channelId: number): string {
    const cache = this.getCache();
    return cache?.[`${channelId}`] ?? '';
  }

  removeMessageCache(channelId: number): void {
    const cache = this.getCache();
    if (!(`${channelId}` in cache)) {
      return;
    }

    delete cache[`${channelId}`];
    this.window.localStorage.setItem(
      this.MESSAGE_CACHE_KEY,
      JSON.stringify(cache)
    );
  }

  private getCache(): { [channelId: string]: string } {
    const storedJson = this.window.localStorage.getItem(this.MESSAGE_CACHE_KEY);
    return !!storedJson
      ? JSON.parse(storedJson)
      : ({} as { [channelId: string]: string });
  }
}
