import { Component, Input, OnInit } from '@angular/core';
import { AudienceAge } from '@cuepid/enterprise/core/models/instagram/audience-statistic';
import { FormBuilder, FormControl } from '@angular/forms';

enum GenderControlOptions {
  ALL,
  MALE,
  FEMALE,
  UNKNOWN,
}

@Component({
  selector: 'app-instagram-audience-age',
  templateUrl: './instagram-audience-age.component.html',
  styleUrls: ['./instagram-audience-age.component.scss']
})
export class InstagramAudienceAgeComponent {

  @Input() audienceAge?: AudienceAge;
  control: FormControl;
  readonly options = GenderControlOptions;

  constructor(fb: FormBuilder) {
    this.control = fb.control(GenderControlOptions.ALL);
  }

  getTotalCount(data: Map<string, number> | undefined): number {
    if (!data) {
      return 0;
    }

    return Array.from(data.values()).reduce((sum, value) => sum + value, 0);
  }

  resolveToMap(data: any): Map<string, number> {
    return data as Map<string, number>;
  }
}
