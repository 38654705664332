import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  @Input() public items: any[] = [];
  @Input() public hasIconMenu = false;
  @Input() public iconMenuTitle = '';

  @Output() public dropdownClicked = new EventEmitter<any>();

  badge = new EventEmitter<{ id: string, enable: boolean }>();

  constructor() {
    this.badge.subscribe(({id, enable}) => {
      const item = this.items.find(tmp => tmp.id === id);
      if (!item) {
        return;
      }
      item.badge = enable;
    });
  }

  onDropDown(item: any) {
    if(item.hasEvents) {
      this.dropdownClicked.emit(item);
    }
  }
}
