<div [ngClass]="{readonly: readonly}">
  <div class="mb-8">
    <div class="mx-4 py-4">
      <label>キャンペーンへの報酬</label>
      <span class="cursor-pointer" fxFlexAlign="center" [matTooltip]="hints.reward" matTooltipPosition="right">
        <mat-icon class="help">help</mat-icon>
      </span>
    </div>
    <ng-container *ngIf="!readonly">
      <mat-button-toggle-group (change)="changeFormState($event.source.value)" [value]="formState" class="mb-24">
        <mat-button-toggle [value]="false">報酬なし</mat-button-toggle>
        <mat-button-toggle [value]="true">報酬あり</mat-button-toggle>
      </mat-button-toggle-group>
    </ng-container>
    <ng-container *ngIf="readonly">
      <div class="mx-16 py-4">
        {{formState ? '報酬あり' : '報酬なし'}}
      </div>
    </ng-container>
  </div>

  <div *ngIf="form.enabled">
    <form [formGroup]="form">

      <div class="mb-8">
        <div class="mx-4 py-4">
          <label>報酬の種類</label>
        </div>
        <ng-container *ngIf="!readonly">
          <mat-button-toggle-group formControlName="type">
            <mat-button-toggle *ngFor="let type of types" [value]="type.code">{{ type.name }}</mat-button-toggle>
          </mat-button-toggle-group>
        </ng-container>
        <ng-container *ngIf="readonly">
          <div class="mx-16 py-4">
            <div *ngFor="let type of types">
              <span *ngIf="form.get('type')!.value === type.code">{{ type.name }}</span>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="mb-8">
        <ng-container *ngIf="!readonly">
          <span *ngIf="form.get('type')!.value === 1" class="amount-label mr-16">フォロワー数</span>
          <mat-form-field *ngIf="medium?.id === instagram.id" class="inline" appearance="outline">
            <input type="number" formControlName="amount" matInput>
            <div class="mr-4" matPrefix>
              <ng-container *ngIf="form.get('type')!.value === 1 || form.get('type')!.value === 2; else fix">
                <span class="amount-prefix">×</span>
              </ng-container>
              <ng-template #fix>
                <span class="amount-prefix">￥</span>
              </ng-template>
            </div>
            <mat-error>金額は必ず入力してください</mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="readonly">
          <div class="mx-16 py-4">
            <span *ngIf="form.get('type')!.value === 1">×</span>
            <span *ngIf="form.get('type')!.value === 2">×</span>
            <span *ngIf="form.get('type')!.value === 3">￥</span>
            {{form.get('amount')!.value}}
          </div>
        </ng-container>
      </div>

    </form>
  </div>
</div>
