import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CropHeaderImageDialogComponent } from '@cuepid/enterprise/shared/components/campaign/crop-header-image-dialog/crop-header-image-dialog.component';
import {
  SelectTemplateComponent,
  SelectTemplateComponentData,
} from '@cuepid/enterprise/shared/components/campaign/select-template/select-template.component';
import { BlobConverter, Campaign, Company } from '@cuepid/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CompanyService } from '@cuepid/enterprise/core/services/company.service';
import { AuthService } from '@cuepid/enterprise/core/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import DescriptionFeatureType = Campaign.DescriptionFeatureType;

type CompanyProfileKind = 'introduction' | 'philosophy';

@Component({
  selector: 'app-input-campaign-description',
  templateUrl: './input-campaign-description.component.html',
  styleUrls: ['./input-campaign-description.component.scss'],
})
export class InputCampaignDescriptionComponent implements OnInit {
  @Input() readonly = false;

  @Input() type?: Campaign.Type;

  @Input() saveAsDraft?: () => void;

  user?: Company;

  form!: FormGroup;

  previewImages: Map<FormGroup, string> = new Map();

  readonly hints = {
    features:
      'インフルエンサーに魅力が伝わるように、募集されるキャンペーンの商品やサービスの特徴をアピール・記載ください。\n1ブロックあたりの推奨文字数：\n　見出し：15文字程度\n　本文：200~300文字程度',
    philosophy:
      '「どのような経緯で」「なぜ」誕生したのか、お届けしたい「想い」を記載ください。\n1ブロックあたりの推奨文字数：\n　見出し：15文字程度\n　本文：200~300文字程度',
  };

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private auth: AuthService,
    private company: CompanyService,
    private router: Router
  ) {
    this.initForm();

    // Add default input
    this.addFeatures('feature');
    this.addFeatures('philosophy');
  }

  ngOnInit(): void {
    this.auth
      .user()
      .pipe(
        // tap(console.log),
        tap((user) => (this.user = user)),
        tap((user) =>
          this.form.get('company_introduction')?.setValue(user.introduction)
        ),
        tap((user) =>
          this.form.get('company_philosophy')?.setValue(user.philosophy)
        )
      )
      .subscribe();
  }

  addFeatures(type: DescriptionFeatureType): void {
    const group = this.resolveFeatureGroup(type);

    group?.addControl(
      Object.keys(group.controls).length.toString(),
      this.makeNewFeatureFormGroup()
    );
  }

  deleteFeature(type: DescriptionFeatureType, controlName: string): void {
    if (!confirm('項目を削除しても宜しいですか？')) {
      return;
    }

    const group = this.resolveFeatureGroup(type);

    group.removeControl(controlName);

    // Rename all control names.
    this.replaceControls(group, this.getControls(group));
  }

  moveToNext(type: DescriptionFeatureType, controlName: string): void {
    const group = this.resolveFeatureGroup(type);

    this.shiftChangeControls(group, +controlName, +controlName + 1);
  }

  moveToPrev(type: DescriptionFeatureType, controlName: string): void {
    const group = this.resolveFeatureGroup(type);

    this.shiftChangeControls(group, +controlName, +controlName - 1);
  }

  hasPrev(type: DescriptionFeatureType, controlName: string): boolean {
    const group = this.resolveFeatureGroup(type);

    return +controlName > 0;
  }

  hasNext(type: DescriptionFeatureType, controlName: string): boolean {
    const group = this.resolveFeatureGroup(type);

    return +controlName < Object.keys(group.controls).length - 1;
  }

  getControls(form: FormGroup, path?: string): AbstractControl[] {
    let group: FormGroup = form;
    if (path) {
      group = form.get(path) as FormGroup;
    }

    const controls: AbstractControl[] = [];

    const keys: number[] = Object.keys(group.controls).map((key) => +key);

    keys.sort((a, b) => a - b);

    for (const key of keys) {
      controls.push(group.controls[key.toString()]);
    }

    return controls;
  }

  getControlName(control: AbstractControl): string {
    const controls = control.parent?.controls as {
      [key: string]: AbstractControl;
    };

    return (
      Object.keys(controls).find((name) => control === controls[name]) || ''
    );
  }

  resolvePreviewImage(group: AbstractControl): string | undefined {
    return this.previewImages.get(group as FormGroup);
  }

  deleteImage(parentFormGroup: AbstractControl): void
  {
    const fileControl          = parentFormGroup.get('image') as FormControl;
    const fileControlForBase64 = parentFormGroup.get('image_base64') as FormControl;

    fileControl.patchValue(null);
    fileControlForBase64.patchValue(null);

    this.previewImages.delete(parentFormGroup as FormGroup);
  }

  onImageSelect(event: Event, parentFormGroup: AbstractControl): void {
    const fileControl = parentFormGroup.get('image') as FormControl;
    const fileControlForBase64 = parentFormGroup.get('image_base64') as FormControl;

    fileControl.markAsTouched();

    const target = event.target as HTMLInputElement;
    if (!target.files || target.files.length === 0) {
      return fileControl.patchValue(null);
    }

    BlobConverter.resize(target.files[0]).subscribe(resized => {
      this.dialog.open(CropHeaderImageDialogComponent, {
        data: resized,
      }).afterClosed().subscribe(croppedImage => {
        if (!croppedImage) {
          target.value = '';
          return fileControl.patchValue(null);
        }

        const blob = BlobConverter.fromDataURL(croppedImage);
        if (!blob) {
          target.value = '';
          return fileControl.patchValue(null);
        }

        fileControl.patchValue(blob);

        BlobConverter.toBase64String(blob).subscribe(string => {
          fileControlForBase64.patchValue(string);
        });
      });
    });
  }

  updateCompanyProfile(kind: CompanyProfileKind): void {
    let msg: string;
    let value: string;

    switch (kind) {
      case 'introduction':
        msg = '企業プロフィールの「説明文」を更新して宜しいですか？';
        value = this.form.get('company_introduction')?.value;
        break;
      case 'philosophy':
        msg = '企業プロフィールの「企業理念や想い」を更新して宜しいですか？';
        value = this.form.get('company_philosophy')?.value;
        break;
    }

    if (!confirm(msg)) {
      return;
    }

    this.patchCompanyData(kind, value).subscribe((_) =>
      alert('企業プロフィールを更新しました。')
    );
  }

  moveToProfileOverview(): void {
    if (!confirm('企業プロフィールへ移動しても宜しいですか？')) {
      return;
    }

    if (this.saveAsDraft) {
      this.saveAsDraft();
    }

    setTimeout(() => {
      this.router.navigateByUrl('/profile/overview');
    }, 1000);
  }

  openTemplateList(
    control: AbstractControl,
    targetElement: HTMLElement,
    type: DescriptionFeatureType
  ) {
    const data: SelectTemplateComponentData = {
      defaultTemplateBody:
        type === 'feature'
          ? this.featureDefaultTemplate()
          : this.philosophyDefaultTemplate(),
    };

    this.dialog
      .open(SelectTemplateComponent, {
        data,
        width: '50vw',
      })
      .afterClosed()
      .pipe(
        filter((value) => !!value),
        tap(() => targetElement.focus()),
        tap((value) => control.patchValue({ body: value }))
      )
      .subscribe();
  }

  restoreData(data: { [key: string]: any }): void {
    this.initForm();

    const features = this.form.get('features') as FormGroup;
    for (const key of Object.keys(data['features'])) {
      const item = this.makeNewFeatureFormGroup();
      item.patchValue(data['features'][key]);
      features.addControl(key, item);
    }
    if (Object.keys(features.controls).length === 0) {
      // Add default input.
      this.addFeatures('feature');
    }

    const philosophies = this.form.get('philosophies') as FormGroup;
    for (const key of Object.keys(data['philosophies'])) {
      const item = this.makeNewFeatureFormGroup();
      item.patchValue(data['philosophies'][key]);
      philosophies.addControl(key, item);
    }
    if (Object.keys(philosophies.controls).length === 0) {
      // Add default input.
      this.addFeatures('philosophy');
    }

    this.form
      .get('company_introduction')
      ?.patchValue(data['company_introduction']);
    this.form.get('company_philosophy')?.patchValue(data['company_philosophy']);
  }

  private replaceControls(form: FormGroup, controls: AbstractControl[]): void {
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        form.removeControl(key);
      }
    }

    for (const control of controls) {
      form.addControl(Object.keys(form.controls).length.toString(), control);
    }
  }

  private shiftChangeControls(form: FormGroup, from: number, to: number): void {
    const fromControl = form.controls[from.toString()];
    const toControl = form.controls[to.toString()];

    if (!toControl) {
      return;
    }

    form.removeControl(to.toString());
    form.removeControl(from.toString());

    form.addControl(to.toString(), fromControl);
    form.addControl(from.toString(), toControl);
  }

  private resolveFeatureGroup(type: DescriptionFeatureType): FormGroup {
    switch (type) {
      case 'feature':
        return this.form.get('features') as FormGroup;
      case 'philosophy':
        return this.form.get('philosophies') as FormGroup;
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      features: this.fb.group({}),
      philosophies: this.fb.group({}),
      company_introduction: ['', [Validators.required]],
      company_philosophy: ['', [Validators.required]],
    });
  }

  private patchCompanyData(key: string, value: any): Observable<Company> {
    return this.company
      .update(key, value)
      .pipe(tap((user) => (this.user = user)));
  }

  private makeNewFeatureFormGroup(): FormGroup {
    const group = this.fb.group({
      title: ['', [Validators.required]],
      body: ['', [Validators.required]],
      image: [null, []],
      image_base64: [null, []],
    });

    const image = group.get('image');
    group.get('image_base64')?.valueChanges.subscribe((string) => {
      if (!image?.value && string) {
        BlobConverter.fromBase64String(string).subscribe((blob) => {
          image?.patchValue(blob);
        });
      }
      // Make preview image.
      if (image?.value) {
        this.previewImages.set(group, URL.createObjectURL(image?.value));
      }
    });

    group.markAsUntouched();

    return group;
  }

  private featureDefaultTemplate(): string {
    return (
      '' +
      `1つのブロックに要素を詰め込みすぎないことを意識しながら「見出し」機能を活かして以下項目例を参考にご記入ください。
例）
●商品概要の簡単な説明。
●使用している素材や成分の特徴。
●商品を利用することで得られる効果や変化、体験。
●販売実績  例）「○年連続売り上げ1位」「これまで○○点の購入実績」
など

■文章に関連する画像がある場合はぜひ登録をお願いします。
　少なくとも本セクションに1点は画像が登録されている状態が望ましいです。
　【推奨サイズ】
　　幅：1200px
　　高さ：750px
　　容量：2MB以内`
    );
  }

  private philosophyDefaultTemplate(): string {
    return (
      '' +
      `1つのブロックに要素を詰め込みすぎないことを意識しながら「見出し」機能を活かして以下項目例を参考にご記入ください。
例）
●「いつ」「誰が」「どんなきっかけ」で誕生したのか。
●商品へのこだわり
●商品開発者が込めた想い
●サービス名、ブランド名に込めた想いや由来
●「誰に」「いつ」「どこで」「どのように」使っていただきたいか
など

■文章に関連する画像がある場合はぜひ登録をお願いします。
　【推奨サイズ】
　　幅：1200px
　　高さ：750px
　　容量：2MB以内`
    );
  }
}
