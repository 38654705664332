import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeService } from './theme.service';
import { RoutePartsService } from './route-parts.service';
import { LayoutService } from './layout.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ThemeService,
    RoutePartsService,
    LayoutService,
  ],
  exports: [
  ]
})
export class SharedServicesModule {
}
