import { Campaign } from '../models/campaign';
import { Influencer } from '../models/influencer';
import { Performance } from '../models/social-account/performance';
import Reward = Campaign.Reward;
import { format, subDays } from 'date-fns';

export abstract class RewardCalculator {
  protected getTargetPerformance(performances: Performance[], collectedAt: string | undefined = undefined): Performance {
    if (!collectedAt) {
      collectedAt = format(subDays(new Date(), 1), 'yyyy-MM-dd');
    }
    const performance = performances.find(p => p.collected_at === collectedAt);

    if (!performance) {
      throw new Error('Could not found the target performance.');
    }
    return performance;
  }

  abstract calculate(reward: Reward, influencer: Influencer, target?: string): number;
}
