import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContractPlan } from '@cuepid/core';
import { ILayoutConf, LayoutService } from '@cuepid/egret';
import { AuthService } from '@cuepid/enterprise/core/services/auth.service';
import { ContractPlanService } from '@cuepid/enterprise/core/services/contract-plan.service';
import { SupportPreReleaseCampaign } from '@cuepid/enterprise/core/trait/support-pre-release-campaign';
import { merge, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Mixin } from 'ts-mixer';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
  styleUrls: ['./header-side.component.scss'],
})
export class HeaderSideComponent
  extends Mixin(SupportPreReleaseCampaign)
  implements OnInit
{
  @Input() notificationPanel: any;
  @ViewChild('banner') banner!: TemplateRef<any>;

  layoutConf?: ILayoutConf;
  currentPlan?: ContractPlan;

  constructor(
    private readonly layout: LayoutService,
    private readonly authService: AuthService,
    private readonly contractPlanService: ContractPlanService,
    protected readonly dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    merge(of({}), this.contractPlanService.contractUpdated)
      .pipe(
        mergeMap(() => this.contractPlanService.getAppliedPlan()),
        tap((plan) => (this.currentPlan = plan))
      )
      .subscribe();
  }

  toggleSidenav() {
    if (this.layoutConf?.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
      });
    }

    this.layout.publishLayoutChange({
      sidebarStyle: 'closed',
    });
  }

  signOut() {
    this.authService.signOut();
  }
}
