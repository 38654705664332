
import { BehaviorSubject, Observable } from 'rxjs';

export interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  count?: BehaviorSubject<number>
  badges?: IBadge[];
  hasEvents?: boolean; // To emit event at dropdown action
  events?: string[]; // To defined event for one or more action
  id?: string;
}

interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  count?: BehaviorSubject<number>;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

export abstract class NavigationService {

  abstract get menuItems(): BehaviorSubject<IMenuItem[]>;
  abstract get menuItems$(): Observable<IMenuItem[]>;
  abstract get iconTypeMenuTitle(): string;
  updateApplicationListCount(newCount: number): void {};
  updateUnapprovedCampaignCount(newCount: number): void {};
}
