import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CuepidCoreModule, PrefecturePipe } from '@cuepid/core';
import { EgretModule } from '@cuepid/egret';
import { InputCampaignDescriptionComponent } from '@cuepid/enterprise/shared/components/campaign/input-campaign-description/input-campaign-description.component';
import { SelectTemplateComponent } from '@cuepid/enterprise/shared/components/campaign/select-template/select-template.component';
import { InstagramAccountBasicInfoComponent } from '@cuepid/enterprise/shared/components/influencer/instagram-account-basic-info/instagram-account-basic-info.component';
import { RecruitmentDetailComponent } from '@cuepid/enterprise/shared/components/influencer/recruitment-overview/dialogs/recruitment-detail/recruitment-detail.component';
import { RecruitmentOverviewComponent } from '@cuepid/enterprise/shared/components/influencer/recruitment-overview/recruitment-overview.component';
import { InlineInstagramGenderRatioComponent } from '@cuepid/enterprise/shared/components/instagram/inline-instagram-gender-ratio/inline-instagram-gender-ratio.component';
import { InstagramPostImageComponent } from '@cuepid/enterprise/shared/components/instagram/instagram-post-image/instagram-post-image.component';
import { LoadIndicatorComponent } from '@cuepid/enterprise/shared/components/load-indicator/load-indicator.component';
import { NegativeUserWarningComponent } from '@cuepid/enterprise/shared/components/negative-user-warning/negative-user-warning.component';
import { PromotionBannerComponent } from '@cuepid/enterprise/shared/components/promotion-banner/promotion-banner.component';
import { InstagramAudienceAgeComponent } from '@cuepid/enterprise/shared/components/report/instagram-audience-age/instagram-audience-age.component';
import { InstagramAudienceAreaComponent } from '@cuepid/enterprise/shared/components/report/instagram-audience-area/instagram-audience-area.component';
import { InstagramAudienceCountryComponent } from '@cuepid/enterprise/shared/components/report/instagram-audience-country/instagram-audience-country.component';
import { InstagramAudienceGenderComponent } from '@cuepid/enterprise/shared/components/report/instagram-audience-gender/instagram-audience-gender.component';
import { InstagramPostOverviewComponent } from '@cuepid/enterprise/shared/components/report/instagram-post-overview/instagram-post-overview.component';
import { InstagramPublicRelationRateComponent } from '@cuepid/enterprise/shared/components/report/instagram-public-relation-rate/instagram-public-relation-rate.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ChartsModule } from 'ng2-charts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgPipesModule } from 'ngx-pipes';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ConfirmRemoveHashtagComponent } from './campaign/confirm-remove-hashtag/confirm-remove-hashtag.component';
import { CropHeaderImageDialogComponent } from './campaign/crop-header-image-dialog/crop-header-image-dialog.component';
import { InputCampaignCoreComponent } from './campaign/input-campaign-core/input-campaign-core.component';
import { InputCampaignDetailComponent } from './campaign/input-campaign-detail/input-campaign-detail.component';
import { InputCampaignRequirementComponent } from './campaign/input-campaign-requirement/input-campaign-requirement.component';
import { InputCampaignRewardComponent } from './campaign/input-campaign-reward/input-campaign-reward.component';
import { ShowBankAccountComponent } from './campaign/show-bank-account/show-bank-account.component';
import { ShowShippingAddressComponent } from './campaign/show-shipping-address/show-shipping-address.component';
import { DirectMessageDialogComponent } from './direct-message-dialog/direct-message-dialog.component';
import { InputMessageTemplateTitleDialogComponent } from './forms/message/input-message-template-title-dialog/input-message-template-title-dialog.component';
import { InputMessageWithTemplateComponent } from './forms/message/input-message-with-template/input-message-with-template.component';
import { HeaderSideComponent } from './header-side/header-side.component';
import { InfluencerAttributeComponent } from './influencer/influencer-attribute/influencer-attribute.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PlanUpgradeToastComponent } from './plan-upgrade-toast/plan-upgrade-toast.component';
import { SidebarSideComponent } from './sidebar-side/sidebar-side.component';

const components = [
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  ConfirmRemoveHashtagComponent,
  InputCampaignCoreComponent,
  InputCampaignDetailComponent,
  InputCampaignDescriptionComponent,
  InputCampaignRequirementComponent,
  InputCampaignRewardComponent,
  ShowShippingAddressComponent,
  ShowBankAccountComponent,
  CropHeaderImageDialogComponent,
  PlanUpgradeToastComponent,
  DirectMessageDialogComponent,
  SelectTemplateComponent,
  InputMessageWithTemplateComponent,
  InputMessageTemplateTitleDialogComponent,
  InstagramAudienceAgeComponent,
  InstagramAudienceAreaComponent,
  InstagramAudienceGenderComponent,
  InstagramAudienceCountryComponent,
  InfluencerAttributeComponent,
  InstagramAccountBasicInfoComponent,
  InstagramPostImageComponent,
  InstagramPostOverviewComponent,
  InstagramPublicRelationRateComponent,
  PromotionBannerComponent,
  LoadIndicatorComponent,
  RecruitmentOverviewComponent,
  RecruitmentDetailComponent,
  NegativeUserWarningComponent,
  InlineInstagramGenderRatioComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatButtonModule,
    MatRippleModule,
    MatListModule,
    EgretModule,
    CuepidCoreModule,
    MatCheckboxModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    NgxMaterialTimepickerModule,
    MatTooltipModule,
    MatDividerModule,
    ImageCropperModule,
    MatCardModule,
    MatButtonToggleModule,
    NgPipesModule,
    ChartsModule,
    MatProgressBarModule,
    LazyLoadImageModule,
    MatChipsModule,
    SlickCarouselModule,
    MatTableModule,
    MatDialogModule,
    MatSortModule,
  ],
  providers: [PrefecturePipe],
  declarations: components,
  exports: components,
})
export class SharedComponentsModule {}
