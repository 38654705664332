<mat-card>
  <mat-card-header fxLayoutAlign="space-between">
    <mat-card-title>年齢</mat-card-title>
    <div fxFlex="4rem">
      <mat-select [formControl]="control">
        <mat-option [value]="options.ALL">合計</mat-option>
        <mat-option [value]="options.MALE">男性</mat-option>
        <mat-option [value]="options.FEMALE">女性</mat-option>
        <mat-option [value]="options.UNKNOWN">その他</mat-option>
      </mat-select>
    </div>
  </mat-card-header>
  <mat-card-content>
    <span fxLayoutAlign="center" *ngIf="!audienceAge">データが存在しません</span>
    <ng-container *ngIf="!!audienceAge" [ngSwitch]="control.value">
      <ng-container *ngSwitchCase="options.ALL" [ngTemplateOutlet]="chart"
                    [ngTemplateOutletContext]="{data: audienceAge?.all, total: getTotalCount(audienceAge?.all)}"></ng-container>
      <ng-container *ngSwitchCase="options.MALE" [ngTemplateOutlet]="chart"
                    [ngTemplateOutletContext]="{data: audienceAge?.male, total: getTotalCount(audienceAge?.male)}"></ng-container>
      <ng-container *ngSwitchCase="options.FEMALE" [ngTemplateOutlet]="chart"
                    [ngTemplateOutletContext]="{data: audienceAge?.female, total: getTotalCount(audienceAge?.female)}"></ng-container>
      <ng-container *ngSwitchCase="options.UNKNOWN" [ngTemplateOutlet]="chart"
                    [ngTemplateOutletContext]="{data: audienceAge?.unknown, total: getTotalCount(audienceAge?.unknown)}"></ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>

<ng-template let-data="data" let-total="total" #chart>
  <div fxLayout="row" *ngFor="let age of resolveToMap(data) | keyvalue | orderBy:'key'">
    <span fxFlex="3rem" fxLayoutAlign="center">{{ age.key }}</span>
    <mat-progress-bar fxFlex="1fr" mode="determinate" [value]="age.value / total * 100"></mat-progress-bar>
    <span fxFlex="3rem" fxLayoutAlign="center">{{ age.value / total | percent:'1.1' }}</span>
  </div>
</ng-template>
