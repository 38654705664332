<div class="recruitment-overview-container">
  <span class="items" (click)="showDetail()">
    <span class="item">
      <span>採用:</span>
      <span>{{ countAcceptedEntries() }}</span>
    </span>
    <span class="item">
      <span>不採用:</span>
      <span>{{ countDeclinedEntries() }}</span>
    </span>
  </span>
</div>
