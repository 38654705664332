import { Prefecture } from '@cuepid/core';

export class AudienceCity {
  areas: { name: string | undefined; value: number }[];
  other = 0;
  sum = 0;

  constructor(data: { [name: string]: number } = {}) {
    this.sum = Object.values(data).reduce((sum, value) => sum + value, 0);
    this.other = data.Other;
    this.areas = Object.entries(data)
      .filter(([name]) => name !== 'Other')
      .filter(([name]) => !!Prefecture.fromNameAlpha(name))
      .map(([name, value]) => ({
        name: Prefecture.fromNameAlpha(name)?.name,
        value,
      }));
  }
}

export class AudienceCountry {
  jp = 0;
  other = 0;
  sum = 0;

  constructor(
    data: { jp: number; other: number; sum: number } = {
      jp: 0,
      other: 0,
      sum: 0,
    }
  ) {
    this.jp = data.jp;
    this.other = data.other;
    this.sum = data.sum;
  }
}

export class AudienceGender {
  female = 0;
  male = 0;
  unknown = 0;
  sum = 0;

  constructor(data: { [label: string]: number } = {}) {
    this.sum = Object.values(data).reduce((sum, value) => sum + value, 0);
    this.female = Object.entries(data)
      .filter(([key]) => key.toLowerCase().startsWith('f.'))
      .reduce((sum, [, value]) => sum + value, 0);
    this.male = Object.entries(data)
      .filter(([key]) => key.toLowerCase().startsWith('m.'))
      .reduce((sum, [, value]) => sum + value, 0);
    this.unknown = Object.entries(data)
      .filter(([key]) => key.toLowerCase().startsWith('u.'))
      .reduce((sum, [, value]) => sum + value, 0);
  }
}

export class AudienceAge {
  all: Map<string, number>;
  female: Map<string, number>;
  male: Map<string, number>;
  unknown: Map<string, number>;

  constructor(data: { [label: string]: number } = {}) {
    this.all = Object.entries(data).reduce((map, [label, value]) => {
      const [, age] = label.split('.');
      map.set(age, value + (map.get(age) ?? 0));
      return map;
    }, new Map());
    this.female = Object.entries(data)
      .filter(([label]) => label.split('.')[0].toLowerCase() === 'f')
      .reduce((map, [label, value]) => {
        const [, age] = label.split('.');
        map.set(age, value + (map.get(age) ?? 0));
        return map;
      }, new Map());
    this.male = Object.entries(data)
      .filter(([label]) => label.split('.')[0].toLowerCase() === 'm')
      .reduce((map, [label, value]) => {
        const [, age] = label.split('.');
        map.set(age, value + (map.get(age) ?? 0));
        return map;
      }, new Map());
    this.unknown = Object.entries(data)
      .filter(([label]) => label.split('.')[0].toLowerCase() === 'u')
      .reduce((map, [label, value]) => {
        const [, age] = label.split('.');
        map.set(age, value + (map.get(age) ?? 0));
        return map;
      }, new Map());
  }
}

export class AudienceStatistic {
  audienceCity?: AudienceCity;
  audienceCountry?: AudienceCountry;
  audienceAge?: AudienceAge;
  audienceGender?: AudienceGender;

  constructor(data: any) {
    if ('audience_city' in data && !!data.audience_city) {
      this.audienceCity = new AudienceCity(data.audience_city);
    }
    if ('audience_country' in data && !!data.audience_country) {
      this.audienceCountry = new AudienceCountry(data.audience_country);
    }
    if ('audience_gender_age' in data && !!data.audience_gender_age) {
      this.audienceAge = new AudienceAge(data.audience_gender_age);
      this.audienceGender = new AudienceGender(data.audience_gender_age);
    }
  }
}
