import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: string | Date | null): string | null {
    if (!value) {
      return null;
    }

    const now = parseInt(format(new Date(), 'yyyyMMdd'), 10);
    let birth: number;

    if (typeof value === 'string') {
      birth = parseInt(format(parseISO(value), 'yyyyMMdd'), 10);
    } else {
      birth = parseInt(format(value, 'yyyyMMdd'), 10);
    }

    const age = Math.floor((now - birth) / 10000);
    return `${age} 歳`;
  }

}
