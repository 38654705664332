import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmRemoveHashtagComponentData {
  tag: string;
  type: 'hashtag' | 'tag';
}

@Component({
  selector: 'app-confirm-remove-hashtag',
  templateUrl: './confirm-remove-hashtag.component.html',
  styleUrls: ['./confirm-remove-hashtag.component.css']
})
export class ConfirmRemoveHashtagComponent implements OnInit {

  constructor(public modalRef: MatDialogRef<ConfirmRemoveHashtagComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmRemoveHashtagComponentData) {
  }

  ngOnInit(): void {
  }

}
