import { Component, Input } from '@angular/core';
import { Influencer } from '@cuepid/core';

@Component({
  selector: 'app-influencer-attribute',
  templateUrl: './influencer-attribute.component.html',
  styleUrls: ['./influencer-attribute.component.scss']
})
export class InfluencerAttributeComponent {

  @Input() influencer?: Influencer;

}
