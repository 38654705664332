<div class="container">
  <div class="top">
    <table class="default-table" [dataSource]="accounts" mat-table>
      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns" mat-row></tr>
      <ng-container matColumnDef="username">
        <th *matHeaderCellDef mat-header-cell>ユーザー名</th>
        <td *matCellDef="let row" mat-cell>{{ row.username }}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>氏名</th>
        <td *matCellDef="let row" mat-cell>{{ row.name || '-' }}</td>
      </ng-container>
      <ng-container matColumnDef="name_kana">
        <th *matHeaderCellDef mat-header-cell>カナ</th>
        <td *matCellDef="let row" mat-cell>{{ row.name_kana || '-' }}</td>
      </ng-container>
      <ng-container matColumnDef="reward">
        <th *matHeaderCellDef mat-header-cell>報酬金額</th>
        <td *matCellDef="let row" mat-cell>{{ row.reward }}</td>
      </ng-container>
      <ng-container matColumnDef="bank_name">
        <th *matHeaderCellDef mat-header-cell>銀行名</th>
        <td *matCellDef="let row" mat-cell>{{ row.bank_name }}</td>
      </ng-container>
      <ng-container matColumnDef="branch_name">
        <th *matHeaderCellDef mat-header-cell>支店名</th>
        <td *matCellDef="let row" mat-cell>{{ row.branch_name }}</td>
      </ng-container>
      <ng-container matColumnDef="account_type">
        <th *matHeaderCellDef mat-header-cell>種別</th>
        <td *matCellDef="let row" mat-cell>
          {{ row.account_type | bankAccountType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="account_number">
        <th *matHeaderCellDef mat-header-cell>口座番号</th>
        <td *matCellDef="let row" mat-cell>{{ row.account_number }}</td>
      </ng-container>
      <ng-container matColumnDef="account_holder">
        <th *matHeaderCellDef mat-header-cell>口座名義</th>
        <td *matCellDef="let row" mat-cell>{{ row.account_holder }}</td>
      </ng-container>
    </table>
  </div>
  <div class="bottom">
    <section fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2rem">
      <button mat-raised-button color="close" mat-dialog-close>閉じる</button>
      <button mat-raised-button color="primary" (click)="copyToClipboard()">
        情報をコピーする
      </button>
    </section>
  </div>
</div>
