import { Pipe, PipeTransform } from '@angular/core';
import { MediumService } from '../services/medium.service';

@Pipe({
  name: 'mediumName'
})
export class MediumNamePipe implements PipeTransform {

  constructor(private readonly mediumService: MediumService) {
  }

  transform(value: number | undefined): string | undefined {
    if (!value) {
      return '';
    }
    return this.mediumService.getMedium(value)?.name;
  }

}
