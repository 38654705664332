<mat-card>
  <mat-card-header>
    <mat-card-title>男女比</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <span fxLayoutAlign="center" *ngIf="!audienceGender"
      >データが存在しません</span
    >
    <ng-container *ngIf="!!audienceGender">
      <canvas
        baseChart
        chartType="doughnut"
        [labels]="labels"
        [data]="[male, female, unknown]"
        [colors]="colors"
        [legend]="false"
        [options]="options"
      ></canvas>
      <div class="data mt-12" gdAuto gdColumns="4.5rem minmax(5rem auto) 4rem">
        <div gdRow="1" gdColumn="1"><span class="marker male"></span> 男性</div>
        <div gdRow="1" gdColumn="2" gdGridAlign="end">
          {{ male | percent: '1.1' }}
        </div>

        <div gdRow="2" gdColumn="1">
          <span class="marker female"></span> 女性
        </div>
        <div gdRow="2" gdColumn="2" gdGridAlign="end">
          {{ female | percent: '1.1' }}
        </div>

        <div gdRow="3" gdColumn="1">
          <span class="marker unknown"></span> その他
        </div>
        <div gdRow="3" gdColumn="2" gdGridAlign="end">
          {{ unknown | percent: '1.1' }}
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
