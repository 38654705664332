import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL, Paginator } from '@cuepid/core';
import { Observable } from 'rxjs';
import { CampaignTemplate } from '@cuepid/enterprise/core/models/campaign-template';

@Injectable({
  providedIn: 'root'
})
export class CampaignTemplateService {

  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private http: HttpClient) {
  }

  all(): Observable<CampaignTemplate[]> {
    return this.http.get<CampaignTemplate[]>(`${this.apiBaseUrl}/company/me/template/campaign`, {
      params: {
        type: 'all',
      },
    });
  }

  list(page: number, perPage: number): Observable<Paginator<CampaignTemplate>> {
    return this.http.get<Paginator<CampaignTemplate>>(`${this.apiBaseUrl}/company/me/template/campaign`, {
      params: {
        page: `${page}`,
        per_page: `${perPage}`
      },
    });
  }

  create(template: CampaignTemplate): Observable<CampaignTemplate> {
    return this.http.post<CampaignTemplate>(`${this.apiBaseUrl}/company/me/template/campaign`, {
      ...template,
    });
  }

  update(template: CampaignTemplate): Observable<CampaignTemplate> {
    return this.http.put<CampaignTemplate>(`${this.apiBaseUrl}/company/me/template/campaign/${template.id}`, {
      ...template,
    });
  }

  delete(template: CampaignTemplate): Observable<any> {
    return this.http.delete(`${this.apiBaseUrl}/company/me/template/campaign/${template.id}`);
  }
}
