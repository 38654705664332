import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  BankAccountType,
  Influencer,
  InfluencerService,
  WINDOW,
} from '@cuepid/core';
import { InstagramEntryRow } from '@cuepid/enterprise/pages/campaign/show-campaign/traits/with-instagram-entry-row';
import { InstagramOfferRow } from '@cuepid/enterprise/pages/campaign/show-campaign/traits/with-instagram-offer-row';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';

type InstagramTableRow = InstagramEntryRow | InstagramOfferRow;

interface DataType {
  rows: InstagramTableRow[];
  via: 'entry' | 'offer';
}

interface Row {
  username: string;
  name: string;
  name_kana: string;
  reward: number;
  bank_name: string;
  branch_name: string;
  account_type: number;
  account_number: number;
  account_holder: string;
}

@Component({
  selector: 'app-show-bank-account',
  templateUrl: './show-bank-account.component.html',
  styleUrls: ['./show-bank-account.component.scss'],
})
export class ShowBankAccountComponent implements OnInit {
  accounts: Row[] = [];

  readonly columns = [
    'username',
    'name',
    'name_kana',
    'reward',
    'bank_name',
    'branch_name',
    'account_type',
    'account_number',
    'account_holder',
  ];

  constructor(
    private readonly influencerService: InfluencerService,
    private readonly toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) private readonly data: DataType,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  ngOnInit(): void {
    this.influencerService
      .getBankAccounts(
        this.data.rows.map((datum) => datum.influencer.id),
        this.data.via
      )
      .subscribe(
        (accounts) =>
          (this.accounts = accounts.map((account) => this.convertRow(account)))
      );
  }

  copyToClipboard(): void {
    const result = this.accounts
      .map((account) => {
        const rows = [
          `${account.username}`,
          `${account.name}`,
          `${account.name_kana}`,
          `${account.reward}`,
          `${account.bank_name}`,
          `${account.branch_name}`,
          `${
            BankAccountType.all().find(
              (type) => type.code === account.account_type
            )?.name
          }`,
          `${account.account_number}`,
          `${account.account_holder}`,
        ];
        return rows.join('\t');
      })
      .join('\r\n');

    from(this.window.navigator.clipboard.writeText(result)).subscribe(() =>
      this.toastrService.info('口座情報をクリップボードにコピーしました。')
    );
  }

  private convertRow(
    account:
      | Influencer.BankAccount
      | Influencer.ShippingAddress
      | InstagramTableRow
  ): Row {
    return {
      username: (account as InstagramEntryRow).influencer.username,
      name: (account as Influencer.ShippingAddress).name,
      name_kana: (account as Influencer.ShippingAddress).name_kana,
      reward: this.resolveReward((account as InstagramTableRow).influencer.id),
      bank_name: (account as Influencer.BankAccount).bank_name,
      branch_name: (account as Influencer.BankAccount).branch_name,
      account_type: (account as Influencer.BankAccount).account_type,
      account_number: (account as Influencer.BankAccount).account_number,
      account_holder: (account as Influencer.BankAccount).account_holder,
    };
  }

  private resolveReward(influencerId: number): number {
    const rows = this.data.rows.filter(
      (tmp) => tmp.influencer.id === influencerId
    );

    if (!rows.length) {
      return 0;
    }

    const row = rows[0];
    if (!('reward' in row) || !row.reward) {
      return 0;
    }

    return row.reward;
  }
}
