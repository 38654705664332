import { Component, Input } from '@angular/core';
import { AudienceGender } from '@cuepid/enterprise/core/models/instagram/audience-statistic';

@Component({
  selector: 'app-inline-instagram-gender-ratio',
  templateUrl: './inline-instagram-gender-ratio.component.html',
  styleUrls: ['./inline-instagram-gender-ratio.component.scss'],
})
export class InlineInstagramGenderRatioComponent {
  @Input() genders?: AudienceGender;
}
