import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WINDOW } from '@cuepid/core';

@Component({
  selector: 'app-crop-header-image-dialog',
  templateUrl: './crop-header-image-dialog.component.html',
  styleUrls: ['./crop-header-image-dialog.component.scss'],
})
export class CropHeaderImageDialogComponent {
  readonly minWidth: number = 370;
  readonly minHeight: number = 240;
  croppedImage: any;

  constructor(
    private readonly ref: MatDialogRef<CropHeaderImageDialogComponent>,
    private readonly ngZone: NgZone,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(MAT_DIALOG_DATA) public file: File
  ) {
    this.file = file;

    // safariを使っているときにコンポーネントが展開されたときに image-cropper が読み込まれず、
    // resizeイベントを発火してやるとコンポーネントの描画が始まるため、以下のコードは不要にならない限り消さない
    this.window.dispatchEvent(new Event('resize'));
  }

  get aspectRatio(): number {
    return this.minWidth / this.minHeight;
  }

  cancel() {
    this.ngZone.run(() => this.ref.close());
  }

  submit() {
    this.ngZone.run(() => {
      this.ref.close(this.croppedImage);
    });
  }
}
