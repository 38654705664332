import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DirectMessageService } from '@cuepid/enterprise/core/services/direct-message.service';
import { Channel } from '@cuepid/core';
import { ToastrService } from 'ngx-toastr';
import { InputMessageWithTemplateComponent } from '@cuepid/enterprise/shared/components/forms/message/input-message-with-template/input-message-with-template.component';

@Component({
  selector: 'app-direct-message-dialog',
  templateUrl: './direct-message-dialog.component.html',
  styleUrls: ['./direct-message-dialog.component.scss'],
})
export class DirectMessageDialogComponent implements OnInit {

  @ViewChild('form') inputForm!: InputMessageWithTemplateComponent;

  constructor(fb: FormBuilder, private readonly messageService: DirectMessageService,
              private readonly ref: MatDialogRef<DirectMessageDialogComponent>,
              private readonly toastrService: ToastrService, @Inject(MAT_DIALOG_DATA) private readonly channels: Channel[]) {
  }

  ngOnInit(): void {
    if (!this.channels?.length) {
      this.ref.close();
      return;
    }
  }

  cancel(): void {
    this.ref.close();
  }

  send(checkbox: MatCheckbox): void {
    if (this.inputForm.messageBodyControl.invalid || !checkbox.checked) {
      return;
    }

    const body = this.inputForm.messageBodyControl.value;
    this.messageService.sendMessageToMultiChannel(body, ...this.channels).pipe(
      tap(() => this.toastrService.info('チェック済みのインフルエンサーにメッセージを送信しました。')),
    ).subscribe(() => this.ref.close(true));
  }
}
