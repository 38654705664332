<span>男性 </span>
<ng-container *ngIf="!!genders">{{
  genders.male / genders.sum | percent: '1.1'
}}</ng-container>
<ng-container *ngIf="!genders">- %</ng-container>
<span class="mx-8">/</span>
<span>女性 </span>
<ng-container *ngIf="!!genders">{{
  genders.female / genders.sum | percent: '1.1'
}}</ng-container>
<ng-container *ngIf="!genders">- %</ng-container>
