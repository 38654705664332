<mat-card>
  <mat-card-header>
    <mat-card-title>国内・海外比率</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <span fxLayoutAlign="center" *ngIf="!audienceCountry"
      >データが存在しません</span
    >
    <ng-container *ngIf="!!audienceCountry">
      <canvas
        baseChart
        chartType="doughnut"
        [colors]="colors"
        [data]="[inJapan, others]"
        [labels]="labels"
        [legend]="false"
        [options]="options"
      ></canvas>
      <div class="data mt-12" gdAuto gdColumns="4.5rem minmax(5rem auto) 4rem">
        <div gdRow="1" gdColumn="1"><span class="marker jp"></span> 国内</div>
        <div gdRow="1" gdColumn="2" gdGridAlign="end">
          {{ inJapan | percent: '1.1' }}
        </div>

        <div gdRow="2" gdColumn="1">
          <span class="marker others"></span> 海外
        </div>
        <div gdRow="2" gdColumn="2" gdGridAlign="end">
          {{ others | percent: '1.1' }}
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
