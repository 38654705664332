import { Component } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Mixin } from 'ts-mixer';
import { SupportPreReleaseCampaign } from '@cuepid/enterprise/core/trait/support-pre-release-campaign';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent extends Mixin(SupportPreReleaseCampaign) {

  constructor(private auth: AuthService) {
    super();
  }

  signIn() {
    this.auth.signIn();
  }
}
