<mat-card class="default">
  <mat-card-header class="dark-blue-400">
    <mat-card-title class="dark-blue-400-fg">メッセージ内容を入力してください</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-input-message-with-template #form></app-input-message-with-template>

    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
      <mat-checkbox fxFlexAlign="center" #confirm>送信される際はチェックを入れてください。</mat-checkbox>
      <button mat-raised-button (click)="cancel()">送信せずに閉じる</button>
      <button class="send" color="primary" [disabled]="form.messageBodyControl.invalid || !confirm.checked"
              (click)="send(confirm)" mat-raised-button>メッセージを送信する
      </button>
    </div>
  </mat-card-content>
</mat-card>
