import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@cuepid/enterprise/env';
import { catchError, map } from 'rxjs/operators';
import { captureException } from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class SkipAuthGuard implements CanActivate {

  private readonly client: HttpClient

  constructor(private readonly router: Router, backend: HttpBackend) {
    this.client = new HttpClient(backend);
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.client.get(`${environment.apiURL}/auth/company/verify`, {
      withCredentials: true,
    }).pipe(
      map(() => this.router.createUrlTree(['/', 'dashboard'])),
      catchError(err => {
        if (!(err instanceof HttpErrorResponse) || err.status !== 401) {
          captureException(err);
        }
        return of(true);
      }),
    );
  }

}
