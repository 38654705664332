import { Component, Input, OnChanges } from '@angular/core';
import { AudienceCity } from '@cuepid/enterprise/core/models/instagram/audience-statistic';

@Component({
  selector: 'app-instagram-audience-area',
  templateUrl: './instagram-audience-area.component.html',
  styleUrls: ['./instagram-audience-area.component.scss']
})
export class InstagramAudienceAreaComponent implements OnChanges {

  @Input() city?: AudienceCity;
  sum = 0;

  ngOnChanges(): void {
    if (!this.city) {
      this.sum = 0;
      return;
    }
    this.sum = this.city.sum - this.city.other;
  }

}
