<div *ngIf="post?.media_type === 'CAROUSEL_ALBUM'" fxLayout="column">
  <ngx-slick-carousel class="carousel" [config]="carouselConfig">
    <div ngxSlickItem *ngFor="let child of post?.children" class="slide">
      <img class="carousel-media" [src]="child.media_url">
    </div>
  </ngx-slick-carousel>
</div>
<div *ngIf="post?.media_type === 'IMAGE'">
  <img class="media" [src]="post?.media_url">
</div>
