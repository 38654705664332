import { Component, Input, OnInit } from '@angular/core';
import { MessageTemplateService } from '@cuepid/enterprise/core/services/message-template.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageTemplate } from '@cuepid/enterprise/core/models/message-template';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { InputMessageTemplateTitleDialogComponent } from '../input-message-template-title-dialog/input-message-template-title-dialog.component';

@Component({
  selector: 'app-input-message-with-template',
  templateUrl: './input-message-with-template.component.html',
  styleUrls: ['./input-message-with-template.component.scss'],
})
export class InputMessageWithTemplateComponent implements OnInit {

  @Input() placeholder = '';

  templates: MessageTemplate[] = [];
  templateControl: FormControl;
  messageBodyControl: FormControl;

  constructor(fb: FormBuilder, private readonly dialog: MatDialog, private readonly toastrService: ToastrService,
              private readonly messageTemplateService: MessageTemplateService) {
    this.templateControl = fb.control('');
    this.messageBodyControl = fb.control('', [Validators.required, Validators.maxLength(3000)]);

    this.templateControl.valueChanges.pipe(
      filter(value => !!value),
    ).subscribe((template: MessageTemplate) => this.messageBodyControl.setValue(template.body));
  }

  ngOnInit(): void {
    this.messageTemplateService.all().subscribe(templates => this.templates = templates);
  }

  openInputTitleDialog() {
    this.messageBodyControl.markAsTouched();
    if (this.messageBodyControl.invalid) {
      this.toastrService.error('赤枠で囲われている箇所を修正してください。', '入力エラーがあります');
      return;
    }

    this.dialog.open(InputMessageTemplateTitleDialogComponent, {
      panelClass: ['card-dialog'],
      data: this.messageBodyControl.value,
    });
  }
}
