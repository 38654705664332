import { Injectable, Renderer2, RendererFactory2, EventEmitter } from '@angular/core';

export interface ITheme {
  name: string,
  baseColor?: string,
  isActive?: boolean
}

@Injectable()
export class ThemeService {
  public onThemeChange: EventEmitter<ITheme> = new EventEmitter();

  public egretThemes: ITheme[] = [{
    'name': 'egret-dark-purple',
    'baseColor': '#9c27b0',
    'isActive': false
  }, {
    'name': 'egret-dark-pink',
    'baseColor': '#e91e63',
    'isActive': false
  }, {
    'name': 'egret-blue',
    'baseColor': '#03a9f4',
    'isActive': true
  }, {
    'name': 'egret-light-purple',
    'baseColor': '#7367f0',
    'isActive': false
  }, {
    'name': 'egret-navy',
    'baseColor': '#10174c',
    'isActive': false
  }];
  public activatedTheme?: ITheme;
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // Invoked in AppComponent and apply 'activatedTheme' on startup
  applyMatTheme(themeName: string) {
    const theme = this.egretThemes.find(t => t.name === themeName);
    if (!theme) {
      return;
    }
    this.activatedTheme = theme;
    this.flipActiveFlag(themeName);

    this.renderer.addClass(document.body, themeName);
  }

  changeTheme(prevTheme: any, themeName: string) {
    this.renderer.removeClass(document.body, prevTheme);
    this.renderer.addClass(document.body, themeName);
    this.flipActiveFlag(themeName);
    this.onThemeChange.emit(this.activatedTheme);
  }

  flipActiveFlag(themeName: string) {
    this.egretThemes.forEach((t) => {
      t.isActive = false;
      if (t.name === themeName) {
        t.isActive = true;
        this.activatedTheme = t;
      }
    });
  }
}
