import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { API_BASE_URL } from '../cuepid-core.module';
import { Paginator } from '../models/paginator';
import { Influencer } from '../models/influencer';
import { map, tap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

export interface InfluencerSearchCondition {
  medium_id?: number;
  category_id?: number;
  min_follower?: number;
  max_follower?: number;
  min_age?: number;
  max_age?: number;
  married?: number;
  with_children?: number;
  belongs_agency?: number;
}

@Injectable({
  providedIn: 'root',
})
export class InfluencerService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private http: HttpClient
  ) {}

  search(
    page: number,
    per_page: number,
    condition: InfluencerSearchCondition,
    sort?: string,
    order?: SortDirection
  ): Observable<Paginator<Influencer>> {
    const params = {
      page,
      per_page,
      ...(condition as any),
    };

    if (!!sort) {
      params.sort = sort;
    }

    if (!!order) {
      params.order = order;
    }

    return this.http
      .get<Paginator<Influencer>>(`${this.apiBaseUrl}/influencer`, { params })
      .pipe(
        tap(
          (paginator) =>
            (paginator.data = plainToClass(Influencer, paginator.data))
        )
      );
  }

  get(id: number): Observable<Influencer> {
    return this.http
      .get<Influencer>(`${this.apiBaseUrl}/influencer/${id}`)
      .pipe(map((data) => plainToClass(Influencer, data)));
  }

  getBankAccount(
    id: number,
    via: 'entry' | 'offer'
  ): Observable<Influencer.BankAccount> {
    return this.http.get<Influencer.BankAccount>(
      `${this.apiBaseUrl}/influencer/${id}/bank-account`,
      {
        params: { via },
      }
    );
  }

  getBankAccounts(
    ids: number[],
    via: 'entry' | 'offer'
  ): Observable<Influencer.BankAccount[]> {
    return this.http.get<Influencer.BankAccount[]>(
      `${this.apiBaseUrl}/bank-accounts`,
      {
        params: {
          'id[]': ids,
          via,
        },
      }
    );
  }

  getShippingAddress(
    id: number,
    via: 'entry' | 'offer'
  ): Observable<Influencer.ShippingAddress> {
    return this.http.get<Influencer.ShippingAddress>(
      `${this.apiBaseUrl}/influencer/${id}/shipping-address`,
      {
        params: { via },
      }
    );
  }
}
