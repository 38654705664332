<div [perfectScrollbar]="{}">
  <form [formGroup]="form" [ngClass]="{ readonly: readonly }">
    <div class="mb-8">
      <div class="mx-4 py-4">
        <label for="name">キャンペーンのお名前（タイトル）</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.example"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>
      <ng-container *ngIf="!readonly">
        <mat-form-field class="full-width" appearance="outline">
          <input
            id="name"
            formControlName="name"
            matInput
            placeholder="入力して下さい"
          />
          <mat-error>キャンペーン名は必ず入力してください</mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          {{ form.get('name')!.value }}
        </div>
      </ng-container>
    </div>

    <div class="mb-8">
      <div class="mx-4 py-4">
        <label>募集方式</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.public"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>
      <ng-container *ngIf="!readonly">
        <mat-button-toggle-group formControlName="public" class="mb-32">
          <mat-button-toggle [value]="true">公開</mat-button-toggle>
          <mat-button-toggle [value]="false">オファー専用</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          {{ form.get('public')!.value ? '公開' : 'オファー専用' }}
        </div>
      </ng-container>
    </div>

    <div class="mb-8">
      <div class="mx-4 py-4">
        <label>カテゴリ</label>
      </div>
      <ng-container *ngIf="!readonly">
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="category_id"
            placeholder="選択して下さい"
          >
            <mat-option>-</mat-option>
            <mat-option
              *ngFor="let category of categories"
              [value]="category.id"
              >{{ category.name }}</mat-option
            >
          </mat-select>
          <mat-error>カテゴリは必ず選択してください</mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          <ng-container *ngFor="let category of categories">
            <span *ngIf="category.id === form.get('category_id')!.value">{{
              category.name
            }}</span>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="!readonly">
      <div class="mb-32">
        <div class="mx-4 py-4">
          <label for="header_image">ヘッダー画像</label>
          <span
            class="cursor-pointer"
            fxFlexAlign="center"
            [matTooltip]="hints.headerImage"
            matTooltipPosition="right"
          >
            <mat-icon class="help">help</mat-icon>
          </span>
        </div>
        <div>
          <input
            id="header_image"
            type="file"
            style="display: none"
            accept="image/*"
            #fileInput
            (change)="onHeaderImageChange($event)"
          />
          <button
            type="button"
            mat-raised-button
            color="primary"
            class="file-select-button"
            (click)="fileInput.click()"
          >
            <mat-icon>attach_file</mat-icon>
            ファイルを選択
          </button>
        </div>
        <div class="error-message">
          <mat-hint *ngIf="!!form.get('id')!.value">
            ※
            ヘッダー画像を新たに指定した場合、今までのヘッダー画像は削除されます。
          </mat-hint>
          <mat-error
            *ngIf="
              form.get('header_image')!.touched &&
              form.hasError('required', 'header_image')
            "
          >
            ヘッダー画像は必ず選択してください
          </mat-error>
        </div>
      </div>

      <div class="mb-32" *ngIf="headerImagePreviewUrl">
        <div class="mx-4 py-4">
          <label for="header_image">画像プレビュー</label>
        </div>
        <img
          [src]="headerImagePreviewUrl | safeUrl"
          alt=""
          class="preview-image"
        />
      </div>
    </ng-container>
    <ng-container *ngIf="readonly">
      <div class="mb-32" *ngIf="headerImagePreviewUrl">
        <div class="mx-4 py-4">
          <label for="header_image">ヘッダー画像</label>
        </div>
        <div class="mx-16 py-4">
          <img
            [src]="headerImagePreviewUrl | safeUrl"
            alt=""
            class="preview-image"
          />
        </div>
      </div>
    </ng-container>

    <div class="mb-24">
      <div class="mx-4 py-4">
        <label for="recruit_start_date">募集と選定の期間を設定</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.recruit"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>
      <div *ngIf="contractPlan?.id === freePlanId" class="notice-for-free-plan">
        ※
        フリープランのため、作成できるキャンペーンは月内に募集終了するもののみとなります
      </div>
      <div fxLayout="row" fxLayoutGap="18px">
        <div class="recruit">
          <div fxLayout="column">
            <div class="title pl-4">募集期間</div>
            <div class="form">
              <ng-container *ngIf="!readonly">
                <mat-form-field
                  class="w-150"
                  appearance="outline"
                  (click)="recruitStart.open()"
                >
                  <mat-label>募集開始日</mat-label>
                  <input
                    id="recruit_start_date"
                    [matDatepicker]="recruitStart"
                    formControlName="recruit_start_date"
                    (dateChange)="
                      setFormattedDate('recruit_start_date', $event.value)
                    "
                    matInput
                    readonly
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="recruitStart"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #recruitStart></mat-datepicker>
                  <mat-error
                    *ngIf="form.hasError('required', 'recruit_start_date')"
                    >募集開始日は必ず指定してください</mat-error
                  >
                  <mat-error
                    *ngIf="form.hasError('period', 'recruit_start_date')"
                    >開始日は終了日より前に設定してください</mat-error
                  >
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="readonly">
                <span class="mx-16">
                  {{ form.get('recruit_start_date')!.value }}
                </span>
              </ng-container>
              <span fxFlexAlign="center" class="px-8">〜</span>
              <ng-container *ngIf="!readonly">
                <mat-form-field
                  class="w-150"
                  appearance="outline"
                  (click)="recruitEnd.open()"
                >
                  <mat-label>募集終了日</mat-label>
                  <input
                    [matDatepicker]="recruitEnd"
                    formControlName="recruit_end_date"
                    (dateChange)="
                      setFormattedDate('recruit_end_date', $event.value)
                    "
                    matInput
                    readonly
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="recruitEnd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #recruitEnd></mat-datepicker>
                  <mat-error
                    *ngIf="form.hasError('required', 'recruit_end_date')"
                    >募集終了日は必ず指定してください</mat-error
                  >
                  <mat-error
                    *ngIf="form.hasError('maxDate', 'recruit_end_date')"
                    >募集終了日は同月内で指定してください</mat-error
                  >
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="readonly">
                <span class="mx-16">
                  {{ form.get('recruit_end_date')!.value }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="selection">
          <div fxLayout="column">
            <div class="title pl-4">選定期間</div>
            <div class="form">
              <ng-container *ngIf="!readonly">
                <mat-form-field
                  class="w-150"
                  appearance="outline"
                  (click)="selectionStart.open()"
                >
                  <mat-label>選定開始日</mat-label>
                  <input
                    [matDatepicker]="selectionStart"
                    formControlName="selection_start_date"
                    (dateChange)="
                      setFormattedDate('selection_start_date', $event.value)
                    "
                    matInput
                    readonly
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="selectionStart"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #selectionStart></mat-datepicker>
                  <mat-hint>選定期間は募集期間以降で設定してください</mat-hint>
                  <mat-error
                    *ngIf="form.hasError('required', 'selection_start_date')"
                    >選定開始日は必ず指定してください</mat-error
                  >
                  <mat-error
                    *ngIf="form.hasError('period', 'selection_start_date')"
                    >開始日は終了日より前に設定してください</mat-error
                  >
                  <mat-error
                    *ngIf="
                      form.hasError(
                        'before_selection_start',
                        'selection_start_date'
                      )
                    "
                  >
                    選定期間は募集期間以降で設定してください
                  </mat-error>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="readonly">
                <span class="mx-16">
                  {{ form.get('selection_start_date')!.value }}
                </span>
              </ng-container>
              <span fxFlexAlign="center" class="px-8">〜</span>
              <ng-container *ngIf="!readonly">
                <mat-form-field
                  class="w-150"
                  appearance="outline"
                  (click)="selectionEnd.open()"
                >
                  <mat-label>選定終了日</mat-label>
                  <input
                    [matDatepicker]="selectionEnd"
                    formControlName="selection_end_date"
                    (dateChange)="
                      setFormattedDate('selection_end_date', $event.value)
                    "
                    matInput
                    readonly
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="selectionEnd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #selectionEnd></mat-datepicker>
                  <mat-error
                    *ngIf="form.hasError('required', 'selection_end_date')"
                    >選定終了日は必ず指定してください</mat-error
                  >
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="readonly">
                <span class="mx-16">
                  {{ form.get('selection_end_date')!.value }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-8">
      <div class="mx-4 py-4">
        <label for="website">キャンペーンに関連するURL（任意）</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.website"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>
      <ng-container *ngIf="!readonly">
        <mat-form-field class="full-width" appearance="outline">
          <input id="website" formControlName="website" matInput />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          {{ form.get('website')!.value }}
        </div>
      </ng-container>
    </div>
  </form>
</div>
