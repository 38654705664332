<div class="recruitment-detail-container">
  <h2>過去のキャンペーン応募履歴</h2>
  <div>
    <table
      class="default-table full-width"
      mat-table
      matSort
      [dataSource]="dataSource"
      (matSortChange)="changeSort($event)"
    >
      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>

      <ng-container matColumnDef="status">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          当落
        </th>
        <td mat-cell *matCellDef="let entry">
          <span *ngIf="!!entry.accepted_at" class="label">当選</span>
          <span *ngIf="!!entry.declined_at" class="label declined">落選</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          キャンペーン募集期間
        </th>
        <td mat-cell *matCellDef="let entry">
          <span>{{ entry.campaign.recruit_start_date }}</span>
          <span>〜</span>
          <span>{{ entry.campaign.recruit_end_date }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
          キャンペーン名
        </th>
        <td mat-cell *matCellDef="let entry">
          <a [href]="'/campaign/' + entry.campaign.id">{{
            entry.campaign.name
          }}</a>
        </td>
      </ng-container>
    </table>
  </div>
  <button type="button" class="btn" (click)="close()">閉じる</button>
</div>
