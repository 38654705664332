import { Component, Input, OnInit } from '@angular/core';
import { InstagramPost } from '@cuepid/core';

@Component({
  selector: 'app-instagram-post-image',
  templateUrl: './instagram-post-image.component.html',
  styleUrls: ['./instagram-post-image.component.scss']
})
export class InstagramPostImageComponent {

  @Input() post?: InstagramPost;

  readonly carouselConfig = {
    dots: true,
    arrows: false,
  };

}
