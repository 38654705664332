import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@cuepid/enterprise/env';
import { Company, WINDOW } from '@cuepid/core';
import Auth0Lock from 'auth0-lock';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root',
  deps: [WINDOW, HttpClient],
})
export class AuthService {

  userUpdated = new EventEmitter<Company>();

  constructor(@Inject(WINDOW) private window: Window, private http: HttpClient) {
  }

  signIn() {
    const lock = new Auth0Lock(environment.auth.clientId, environment.auth.domain, {
      avatar: null,
      allowSignUp: false,
      allowForgotPassword: true,
      allowShowPassword: true,
      configurationBaseUrl: environment.auth.configurationBaseUrl,
      defaultDatabaseConnection: environment.auth.defaultDatabaseConnection,
      auth: {
        ...environment.auth.lockAuthOptions,
        responseType: 'code',
        redirect: true,
        params: {
          protocol: 'oauth2',
          scope: 'openid profile email offline_access',
        },
        sso: false,
      },
      language: 'ja',
      languageDictionary: {
        title: 'ログイン',
      },
      theme: {
        logo: '/assets/images/logos/cuepid.png',
      },
    });
    lock.show();
  }

  verifyAuthenticate(): Observable<boolean> {
    return this.http.get(`${environment.apiURL}/auth/company/verify`).pipe(
      map(() => true),
    );
  }

  user(withProperties: string[] = []): Observable<Company> {
    return this.http.get<Company>(`${environment.apiURL}/company/me`, {
      params: {
        'with[]': withProperties,
      }
    }).pipe(
      map(user => plainToClass(Company, user)),
    );
  }

  signOut() {
    this.window.location.href = `${environment.apiURL}/auth/company/signOut`;
  }
}
