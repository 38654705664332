<div class="app-admin-wrap">
  <!-- Header for top navigation layout -->
  <!-- Main Container -->
  <mat-sidenav-container [ngClass]="adminContainerClasses"
                         class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}">
    <mat-sidenav-content>
      <!-- SIDEBAR -->
      <app-sidebar-side *ngIf="layoutConf.navigationPos === 'side'" (mouseenter)="sidebarMouseenter()"
                        (mouseleave)="sidebarMouseleave()"></app-sidebar-side>

      <!-- App content -->
      <div class="main-content-wrap" id="main-content-wrap" [perfectScrollbar]=""
           [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
        <!-- Header for side navigation layout -->
        <app-header-side *ngIf="layoutConf.navigationPos === 'side'"></app-header-side>

        <div class="rightside-content-hold" id="rightside-content-hold" [perfectScrollbar]="scrollConfig"
             [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
          <!-- View Loader -->
          <div class="view-loader" *ngIf="isModuleLoading" style="position:fixed;"
               fxLayout="column" fxLayoutAlign="center center">
            <div class="spinner">
              <div class="double-bounce1 mat-bg-accent"></div>
              <div class="double-bounce2 mat-bg-primary"></div>
            </div>
          </div>
          <!-- Breadcrumb -->
          <app-breadcrumb></app-breadcrumb>
          <!-- View outlet -->
          <router-outlet></router-outlet>
          <span class="m-auto" *ngIf="!layoutConf.footerFixed"></span>
          <app-footer *ngIf="!layoutConf.footerFixed" style="display: block; margin: 0 -.333rem -.33rem"></app-footer>
        </div>
        <span class="m-auto" *ngIf="layoutConf.footerFixed"></span>
        <app-footer *ngIf="layoutConf.footerFixed"></app-footer>
      </div>

      <!-- View overlay for mobile navigation -->
      <div [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}" class="sidebar-backdrop"
           (click)="closeSidebar()"></div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
