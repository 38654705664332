import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL, Influencer } from '@cuepid/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import ShippingAddress = Influencer.ShippingAddress;

@Injectable({
  providedIn: 'root',
})
export class ShippingAddressService {
  constructor(
    @Inject(API_BASE_URL) private readonly apiBaseUrl: string,
    private readonly http: HttpClient
  ) {}

  getList(id: number[], via: 'entry' | 'offer'): Observable<ShippingAddress[]> {
    return this.http.get<ShippingAddress[]>(
      `${this.apiBaseUrl}/shipping-address`,
      {
        params: {
          'id[]': id,
          via,
        },
      }
    );
  }
}
