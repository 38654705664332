import { Component, Input } from '@angular/core';
import { Influencer } from '@cuepid/core';
import { NegativeInfluencerHandle } from '@cuepid/enterprise/core/trait/negative-influencer-handle';

@Component({
  selector: 'app-negative-user-warning',
  templateUrl: './negative-user-warning.component.html',
  styleUrls: ['./negative-user-warning.component.scss'],
})
export class NegativeUserWarningComponent extends NegativeInfluencerHandle {
  @Input() influencer?: Influencer;
}
