import { Pipe, PipeTransform } from '@angular/core';
import { AgencyOption } from '../constant/agency-option';

@Pipe({name: 'agency'})
export class AgencyPipe implements PipeTransform {

  transform(code: number | undefined): string | null | undefined {
    if (!code) {
      return null;
    }
    return AgencyOption.fromCode(code)?.name;
  }

}
