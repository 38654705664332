<mat-card-content>
  <div class="cropper">
    <image-cropper [imageFile]="file" [cropperMinWidth]="minWidth" [cropperMinHeight]="minHeight" [format]="'jpeg'" [imageQuality]="92"
                   [aspectRatio]="aspectRatio" (imageCropped)="croppedImage = $event.base64">
    </image-cropper>
  </div>
</mat-card-content>
<mat-card-actions>
  <div class="my-12 mx-24" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
    <button mat-raised-button (click)="cancel()">キャンセル</button>
    <button mat-raised-button color="primary" (click)="submit()">確定する</button>
  </div>
</mat-card-actions>
