<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf?.sidebarStyle !== 'compact'"
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
    mat-icon-button
  >
    <mat-icon>menu</mat-icon>
  </button>
  <ng-container *ngIf="canShowPreReleaseCampaignComponent()">
    <a class="open-banner mr-12" mat-button (click)="openCampaignBanner()">
      キャンペーン適用中
    </a>
  </ng-container>
  <span class="contract-info" fxLayout="row" fxLayoutGap="4px">
    <span>{{ currentPlan?.name }}</span>
    <span>期間:</span>
    <span
      >{{ currentPlan?.pivot?.start_date | date:'yyyy-MM-dd' }}～{{
      currentPlan?.pivot?.end_date | date:'yyyy-MM-dd' }}</span
    >
    <span *ngIf="currentPlan?.isExtendable">[自動更新]</span>
  </span>

  <span fxFlex></span>

  <!-- Top left user menu -->
  <button (click)="signOut()" mat-button>
    <mat-icon>exit_to_app</mat-icon>
    ログアウト
  </button>
</mat-toolbar>
