import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMenuItem, NavigationService } from '@cuepid/egret';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseNavigationService extends NavigationService {
  private static readonly MENUS: IMenuItem[] = [
    {
      type: 'separator',
      name: 'メインメニュー',
    },
    {
      name: 'ダッシュボード',
      type: 'link',
      icon: 'dashboard',
      state: 'dashboard',
    },
    {
      name: 'キャンペーン管理',
      type: 'dropDown',
      icon: 'format_list_bulleted',
      state: 'campaign',
      sub: [
        { name: '新規キャンペーン作成', state: 'select_creation_method' },
        { name: 'キャンペーン一覧', state: '' },
      ],
    },
    {
      name: 'インフルエンサー検索',
      type: 'link',
      icon: 'search',
      state: 'influencer/search',
    },
    {
      id: 'message',
      name: 'メッセージ',
      type: 'link',
      icon: 'chat',
      state: 'message',
    },
    {
      name: 'テンプレート管理',
      type: 'dropDown',
      icon: 'description',
      state: '',
      sub: [
        {
          name: 'メッセージテンプレート',
          type: 'link',
          state: 'config/template/message',
        },
        {
          name: 'キャンペーンテンプレート',
          type: 'link',
          state: 'config/template/campaign',
        },
      ],
    },
    {
      name: '設定',
      type: 'dropDown',
      icon: 'settings_applications',
      state: '',
      sub: [
        {
          name: '企業プロフィール',
          type: 'link',
          state: 'profile/overview',
        },
        {
          name: '契約プラン変更',
          type: 'link',
          state: 'plan',
        },
      ],
    },
    {
      name: 'Q & A',
      type: 'link',
      state: 'support/questions',
      icon: 'live_help',
    },
    {
      name: 'お知らせ',
      type: 'link',
      state: 'support/information',
      icon: 'info',
    },
    {
      name: 'サービス利用規約',
      type: 'extLink',
      state: 'information/terms',
    },
  ];
  readonly iconTypeMenuTitle = 'Frequently Accessed';
  readonly menuItems = new BehaviorSubject(EnterpriseNavigationService.MENUS);
  readonly menuItems$ = this.menuItems.asObservable();
}
