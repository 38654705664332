import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AdministrationService } from '@cuepid/core';
import { EMPTY, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export abstract class CheckMaintenanceModeInterceptor
  implements HttpInterceptor
{
  protected constructor(
    private readonly administrationService: AdministrationService,
    private readonly router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.administrationService.enableMaintenanceMode()) {
      return next.handle(request);
    }

    return this.administrationService.getMaintenanceStatus().pipe(
      mergeMap((status) => {
        if (status && status.in_maintenance) {
          this.router.navigate(this.resolveMaintenancePagePath(), {
            state: status,
          });
          return EMPTY;
        }
        return next.handle(request);
      })
    );
  }

  abstract resolveMaintenancePagePath(): string[];
}
