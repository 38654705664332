import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  BlobConverter,
  Category,
  CategoryService,
  ContractPlan,
} from '@cuepid/core';
import { DateValidator } from '@cuepid/enterprise/core/validators/date.validator';
import { CropHeaderImageDialogComponent } from '@cuepid/enterprise/shared/components/campaign/crop-header-image-dialog/crop-header-image-dialog.component';
import {
  endOfMonth,
  format,
  isBefore,
  isDate,
  isSameDay,
  parseISO,
} from 'date-fns';
import { CustomValidators } from 'ngx-custom-validators';
import { EMPTY } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-input-campaign-core',
  templateUrl: './input-campaign-core.component.html',
  styleUrls: ['./input-campaign-core.component.scss'],
})
export class InputCampaignCoreComponent implements OnInit, OnChanges {
  @Input() readonly = false;
  @Input() contractPlan?: ContractPlan;

  form: FormGroup;
  categories: Category[] = [];

  headerImagePreviewUrl?: string;

  readonly hints = {
    example:
      'インフルエンサーに魅力が伝わるようなキャンペーンタイトルを設定ください。\n推奨文字数：30文字程度',
    public:
      '⚪︎公開\n　通常募集\n⚪︎オファー専用\n　特定のインフルエンサーに個別オファーできるプランです。\n　キャンペーン作成後に「インフルエンサー検索」からお好みの方へ直接オファーを行います。\n\n※「公開」の場合も個別にオファー可能です。',
    headerImage: '推奨サイズ：1200px x 750px  2MB以内\n推奨アスペクト比：約8:5',
    recruit:
      '推奨募集期間として1週間～4週間を目安に設定ください。\n尚、選定期間は必ず「募集期間以降」でご指定ください。',
    website: '該当商品やキャンペーン紹介ページなどのURLをご入力ください。',
  };

  readonly dateFields = [
    'recruit_start_date',
    'recruit_end_date',
    'selection_start_date',
    'selection_end_date',
  ];

  readonly freePlanId = ContractPlan.ID.FREE_PLAN;

  constructor(
    private fb: FormBuilder,
    private category: CategoryService,
    private dialog: MatDialog
  ) {
    const recruitStartDate = fb.control('');
    const recruitEndDate = fb.control('');
    const selectionStartDate = fb.control('');
    const selectionEndDate = fb.control('');

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      website: ['', [CustomValidators.url]],
      header_image: [null, [Validators.required]],
      header_image_base64: [null, []],
      category_id: ['', [Validators.required]],
      public: [true, [Validators.required]],
      recruit_start_date: recruitStartDate,
      recruit_end_date: recruitEndDate,
      selection_start_date: selectionStartDate,
      selection_end_date: selectionEndDate,

      // 更新時、以下のフィールドに値が入る
      id: [null, []],
      company_id: [null, []],
    });

    const recruitDateValidators = [
      Validators.required,
      CustomValidators.date,
      DateValidator.isValidPeriod(recruitStartDate, recruitEndDate),
    ];
    recruitStartDate.setValidators(recruitDateValidators);
    recruitStartDate.valueChanges.subscribe(() =>
      recruitEndDate.updateValueAndValidity({ emitEvent: false })
    );
    recruitEndDate.setValidators(recruitDateValidators);
    recruitEndDate.valueChanges.subscribe(() =>
      recruitStartDate.updateValueAndValidity({ emitEvent: false })
    );

    const selectionDateValidators = [
      Validators.required,
      CustomValidators.date,
      DateValidator.isValidPeriod(selectionStartDate, selectionEndDate),
    ];
    selectionStartDate.setValidators([
      ...selectionDateValidators,
      this.isSelectionPeriodLaterThanRecruitPeriod(
        recruitEndDate,
        selectionStartDate
      ),
    ]);
    selectionStartDate.valueChanges.subscribe(() =>
      selectionEndDate.updateValueAndValidity({ emitEvent: false })
    );
    selectionEndDate.setValidators(selectionDateValidators);
    selectionEndDate.valueChanges.subscribe(() =>
      selectionStartDate.updateValueAndValidity({ emitEvent: false })
    );

    const headerImage = this.form.get('header_image');
    this.form.get('header_image_base64')?.valueChanges.subscribe((string) => {
      if (!headerImage?.value) {
        BlobConverter.fromBase64String(string).subscribe((blob) => {
          headerImage?.patchValue(blob);
        });
      }
      // Make preview image.
      this.headerImagePreviewUrl = URL.createObjectURL(headerImage?.value);
    });
  }

  ngOnInit() {
    this.category
      .all()
      .subscribe((categories) => (this.categories = categories));
  }

  ngOnChanges() {
    const recruitStartDate = this.form.get('recruit_start_date') as FormControl;
    const recruitEndDate = this.form.get('recruit_end_date') as FormControl;
    const recruitDateValidators = [
      Validators.required,
      CustomValidators.date,
      DateValidator.isValidPeriod(recruitStartDate, recruitEndDate),
    ];

    if (!this.contractPlan || this.contractPlan.id === this.freePlanId) {
      const target = format(endOfMonth(new Date()), 'yyyy-MM-dd');
      recruitEndDate.setValidators([
        ...recruitDateValidators,
        CustomValidators.maxDate(target),
      ]);
      return;
    }

    recruitEndDate.setValidators(recruitDateValidators);
  }

  setFormattedDate(formControlName: string, value: unknown) {
    this.form.controls[formControlName].setValue(
      format(value as Date, 'yyyy-MM-dd')
    );
    this.dateFields.forEach((field) => {
      this.form.controls[field].updateValueAndValidity();
    });
  }

  onHeaderImageChange(event: Event): void {
    const headerImage = this.form.get('header_image');
    const headerImageBase64 = this.form.get('header_image_base64');
    if (!headerImage || !headerImageBase64) {
      return;
    }
    const target = event.target as HTMLInputElement;
    const { files } = target;
    if (!files || files.length === 0) {
      return;
    }

    BlobConverter.resize(files[0])
      .pipe(
        tap(() => (target.value = '')),
        mergeMap((resized) =>
          this.dialog
            .open(CropHeaderImageDialogComponent, {
              data: resized,
            })
            .afterClosed()
        ),
        mergeMap((cropped) => {
          if (!cropped) {
            headerImage.markAsTouched();
            return EMPTY;
          }

          const blob = BlobConverter.fromDataURL(cropped);
          if (!blob) {
            headerImage.patchValue(null);
            return EMPTY;
          }

          headerImage.patchValue(blob);
          headerImage.markAsTouched();
          return BlobConverter.toBase64String(blob);
        }),
        tap((encoded) => headerImageBase64.patchValue(encoded))
      )
      .subscribe();
  }

  restoreData(data: { [key: string]: any }, isEdit: boolean): void {
    const ignoreKeys = isEdit ? [] : ['id', 'company_id'];
    for (const formName of Object.keys(this.form.controls)) {
      if (ignoreKeys.includes(formName)) {
        continue;
      }
      if (data.hasOwnProperty(formName)) {
        this.form.get(formName)?.patchValue(data[formName]);
      }
    }

    if (data['header_image_url']) {
      BlobConverter.fromUrl(data['header_image_url']).subscribe((blob) => {
        if (!blob) {
          return;
        }
        this.form.get('header_image')?.patchValue(blob);
        BlobConverter.toBase64String(blob).subscribe((string) => {
          this.form.get('header_image_base64')?.patchValue(string);
        });
      });
    }
  }

  private isSelectionPeriodLaterThanRecruitPeriod(
    recruitEnd: FormControl,
    selectionStart: FormControl
  ) {
    return (): { [key: string]: any } => {
      if (!recruitEnd.value || !selectionStart.value) {
        return {};
      }

      const recruitEndDate = isDate(recruitEnd.value)
        ? recruitEnd.value
        : parseISO(recruitEnd.value);
      const selectionStartDate = isDate(selectionStart.value)
        ? selectionStart.value
        : parseISO(selectionStart.value);
      if (
        isSameDay(recruitEndDate, selectionStartDate) ||
        isBefore(recruitEndDate, selectionStartDate)
      ) {
        return {};
      }

      return { before_selection_start: true };
    };
  }
}
