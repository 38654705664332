import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdministrationService,
  CheckMaintenanceModeInterceptor as BaseInterceptor,
} from '@cuepid/core';

@Injectable()
export class CheckMaintenanceModeInterceptor extends BaseInterceptor {
  constructor(administrationService: AdministrationService, router: Router) {
    super(administrationService, router);
  }

  resolveMaintenancePagePath(): string[] {
    return ['/', 'maintenance'];
  }
}
