<mat-card class="default">
  <mat-card-header class="dark-blue-700">
    <mat-card-title class="dark-blue-700-fg">テンプレート名を入力して下さい</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>テンプレート名</mat-label>
        <input name="name" [formControl]="templateNameControl" matInput>
        <mat-hint>30文字以内でご入力ください。</mat-hint>
        <mat-error *ngIf="templateNameControl.hasError( 'maxlength')">
          テンプレート名は30文字以内でご入力ください。
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="end">
      <button mat-button (click)="close()">キャンセル</button>
      <button mat-raised-button color="primary" (click)="saveTemplate()" [disabled]="templateNameControl.invalid">
        <i class="material-icons">edit</i>テンプレートを作成する
      </button>
    </div>
  </mat-card-content>
</mat-card>
