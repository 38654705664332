import { RewardCalculator } from './reward.calculator';
import { Campaign } from '../models/campaign';
import BigNumber from 'bignumber.js';
import { Influencer, InstagramPerformance } from '@cuepid/core';

export class InstagramRewardCalculator extends RewardCalculator {
  calculate(reward: Campaign.Reward, influencer: Influencer, target?: string): number {
    const performances = influencer?.instagram_account?.performances ?? [];
    const performance = this.getTargetPerformance(performances, target) as InstagramPerformance;
    const baseAmount = new BigNumber(reward.amount);

    switch (reward.type) {
      case 1:
        return baseAmount.multipliedBy(performance.follower).dp(0).toNumber();
      case 2:
        return baseAmount.multipliedBy(performance.average_like).dp(0).toNumber();
      case 3:
        return baseAmount.toNumber();
      default:
        throw new Error('Invalid code type of reward.');
    }
  }
}
