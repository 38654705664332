import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AdministrationService as BaseService,
  API_BASE_URL,
  Entry,
  InfluencerOffer,
  MaintenanceOption,
  MAINTENANCE_OPTION,
} from '@cuepid/core';
import { COOKIE_SERVICE } from '@cuepid/enterprise/core/injection-tokens';
import { CookiesStatic } from 'js-cookie';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService extends BaseService {
  private readonly PROXY_HASH_COOKIE_NAME = 'CUEPID_ADMINISTRATOR_PROXY_HASH';

  constructor(
    backend: HttpBackend,
    private readonly http: HttpClient,
    @Inject(MAINTENANCE_OPTION) maintenanceOption: MaintenanceOption,
    @Inject(API_BASE_URL) private readonly apiBaseUrl: string,
    @Inject(COOKIE_SERVICE) private readonly cookieService: CookiesStatic
  ) {
    super(backend, maintenanceOption);
  }

  isInProxyLogin(): boolean {
    return !!this.cookieService.get(this.PROXY_HASH_COOKIE_NAME);
  }

  changeEntryStatus(entry: Entry, status: number): Observable<Entry> {
    return this.http.put<Entry>(
      `${this.apiBaseUrl}/administration/entry/${entry.id}/status`,
      {
        status,
      }
    );
  }

  changeOfferStatus(offer: InfluencerOffer, status: number): Observable<Entry> {
    return this.http.put<Entry>(
      `${this.apiBaseUrl}/administration/offer/${offer.pivot.offer_id}/influencer/${offer.id}/status`,
      {
        status,
      }
    );
  }
}
