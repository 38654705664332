import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine'
})
export class NewLinePipe implements PipeTransform {

  transform(value: string | undefined): string | undefined {
    return value?.replace(/\n/gi, '<br>');
  }

}
