import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL, Paginator } from '@cuepid/core';
import { Observable } from 'rxjs';
import { MessageTemplate } from '@cuepid/enterprise/core/models/message-template';

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {

  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private http: HttpClient) {
  }

  all(): Observable<MessageTemplate[]> {
    return this.http.get<MessageTemplate[]>(`${this.apiBaseUrl}/company/me/template/message`, {
      params: {
        type: 'all',
      },
    });
  }

  list(page: number, perPage: number): Observable<Paginator<MessageTemplate>> {
    return this.http.get<Paginator<MessageTemplate>>(`${this.apiBaseUrl}/company/me/template/message`, {
      params: {
        page: `${page}`,
        per_page: `${perPage}`
      },
    });
  }

  create(template: MessageTemplate): Observable<MessageTemplate> {
    return this.http.post<MessageTemplate>(`${this.apiBaseUrl}/company/me/template/message`, {
      ...template,
    });
  }

  update(template: MessageTemplate): Observable<MessageTemplate> {
    return this.http.put<MessageTemplate>(`${this.apiBaseUrl}/company/me/template/message/${template.id}`, {
      ...template,
    });
  }

  delete(template: MessageTemplate): Observable<any> {
    return this.http.delete(`${this.apiBaseUrl}/company/me/template/message/${template.id}`);
  }
}
