<div class="seciton-left">
  <div class="section-left-content">
  </div>
</div>

<div class="form-holder height-100vh mat-elevation-z4" fxLayout="column" fxFlexAlign="center">
  <div class="my-auto">
    <div class="mb-48" fxLayout="row wrap" fxLayoutAlign="center center">
      <img width="250px" src="/assets/images/logos/cuepid.png" alt=""/>
    </div>

    <div class="auth mb-24 mx-24" fxLayout="column" fxLayoutGap="16px">
      <div fxLayoutAlign="center" fxLayout="row">
        <button mat-raised-button color="primary" type="button" (click)="signIn()">ログイン</button>
      </div>
      <p fxLayoutAlign="center">or</p>
      <div fxLayoutAlign="center" fxLayout="row">
        <a mat-flat-button color="accent" routerLink="/registration/new">新規登録</a>
      </div>
    </div>
  </div>
</div>

<div class="banner" *ngIf="canShowPreReleaseCampaignComponent()">
  <a href="https://cuepid.co.jp/enterprise/lp/02">
    <img src="/assets/images/banner/price-down-s.png">
  </a>
</div>
