import { Pipe, PipeTransform } from '@angular/core';
import { BankAccountType } from '../constant/bank-account-type';

@Pipe({
  name: 'bankAccountType'
})
export class BankAccountTypePipe implements PipeTransform {

  transform(value: number | undefined): string | null | undefined {
    if (!value) {
      return null;
    }
    return BankAccountType.all().find(type => type.code === value)?.name;
  }

}
