<div class="sidebar-panel">
  <div
    id="scroll-area"
    [perfectScrollbar]
    class="navigation-hold"
    fxLayout="column"
  >
    <div class="sidebar-hold">
      <!-- App Logo -->
      <div class="branding">
        <img src="assets/images/side_logo.png" alt="" class="app-logo" />
      </div>

      <!-- Sidebar user -->
      <div class="app-user">
        <div class="app-user-photo white-100">
          <mat-icon
            *ngIf="!user?.icon_url"
            class="rounded-circle mat-elevation-z1 white-100 py-32"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center"
            fxFlexAlign="center"
          >
            people
          </mat-icon>
          <img
            *ngIf="!!user?.icon_url"
            alt="user-icon"
            [src]="user?.icon_url"
          />
        </div>
        <span
          class="app-user-name mb-05"
          fxLayout="column"
          fxFlexAlign="center"
        >
          <span>{{ user?.name }}</span>
          <span *ngIf="isInProxyLogin()">（代理ログイン中）</span>
        </span>
      </div>

      <!-- Navigation -->
      <app-sidenav
        [items]="menuItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="iconTypeMenuTitle"
        #nav
      ></app-sidenav>
    </div>
  </div>
</div>
