import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignTemplateService } from '@cuepid/enterprise/core/services/campaign-template.service';
import { CampaignTemplate } from '@cuepid/enterprise/core/models/campaign-template';
import { ToastrService } from 'ngx-toastr';
import { WINDOW } from '@cuepid/core';

export interface SelectTemplateComponentData {
  defaultTemplateBody?: string;
}

@Component({
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss'],
})
export class SelectTemplateComponent implements OnInit {
  templates: CampaignTemplate[] = [];

  selectedTemplate?: CampaignTemplate;

  currentTemplateBody = '';

  constructor(
    private campaignTemplateService: CampaignTemplateService,
    private ref: MatDialogRef<SelectTemplateComponent>,
    private toastr: ToastrService,
    @Inject(WINDOW) private window: Window,
    @Inject(MAT_DIALOG_DATA) private readonly data: SelectTemplateComponentData
  ) {}

  ngOnInit(): void {
    if (this.data.defaultTemplateBody) {
      this.currentTemplateBody = this.data.defaultTemplateBody;
    }

    this.campaignTemplateService
      .all()
      .subscribe((templates) => (this.templates = templates));
  }

  select() {
    this.ref.close(this.currentTemplateBody);
  }

  close() {
    this.ref.close();
  }

  changeTemplate(): void {
    this.currentTemplateBody = this.selectedTemplate?.body || '';
  }

  updateTemplate(): void {
    if (!this.selectedTemplate) {
      return;
    }

    if (
      !this.window.confirm('この内容でテンプレートを更新して宜しいですか？')
    ) {
      return;
    }

    this.selectedTemplate.body = this.currentTemplateBody;
    this.campaignTemplateService
      .update(this.selectedTemplate)
      .subscribe((_) => this.toastr.info('テンプレートを更新しました。'));
  }
}
