<mat-card-content>
  <mat-form-field appearance="outline" class="full-width">
    <mat-select placeholder="テンプレートから選ぶ" [(value)]="selectedTemplate" (openedChange)="changeTemplate()">
      <mat-option>-</mat-option>
      <mat-option *ngFor="let template of templates" [value]="template">{{ template.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="preview mb-0 block" appearance="outline">
    <textarea matInput [(ngModel)]="currentTemplateBody"></textarea>
  </mat-form-field>
  <span class="action-link-text block update-template" (click)="updateTemplate()" *ngIf="!!selectedTemplate">上記メッセージでテンプレートを更新する</span>
</mat-card-content>
<mat-card-actions fxLayout="row" class="mt-16">
  <button class="mx-8 cancel" mat-raised-button (click)="close()">キャンセル</button>
  <button class="mx-8 insert" mat-raised-button color="primary" (click)="select()" [disabled]="!currentTemplateBody">挿入する</button>
</mat-card-actions>
