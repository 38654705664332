import { Company } from '@cuepid/core';
import { PlanUpgradeToastComponent } from '@cuepid/enterprise/shared/components/plan-upgrade-toast/plan-upgrade-toast.component';
import { ToastrService } from 'ngx-toastr';

export class ShowPlanUpgradeToast {
  protected readonly toastrService!: ToastrService;

  protected showUpgradeToast(user: Company, message: string | null = null) {
    if (!user.isReachedToOfferLimit() && !user.isReachedToEntryLimit()) {
      return;
    }

    const toast = this.toastrService.toasts.find(t => t.portal.instance instanceof PlanUpgradeToastComponent);
    if (!!toast) {
      return;
    }

    let title: string;
    if (!!message) {
      title = message;
    } else if (user.isReachedToOfferLimit()) {
      title = '今月のオファー送信上限に到達しています。';
    } else {
      title = '今月のエントリー受諾上限に到達しています。';
    }

    this.toastrService.warning('', title, {
      disableTimeOut: true,
      closeButton: true,
      toastComponent: PlanUpgradeToastComponent,
    });
  }
}
