import { MatDialog } from '@angular/material/dialog';
import { PromotionBannerComponent } from '@cuepid/enterprise/shared/components/promotion-banner/promotion-banner.component';

export class SupportPreReleaseCampaign {
  protected dialog!: MatDialog;

  canShowPreReleaseCampaignComponent(): boolean {
    return false;
  }

  openCampaignBanner(): void {
    this.dialog.open(PromotionBannerComponent);
  }
}
