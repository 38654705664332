import { Pipe, PipeTransform } from '@angular/core';
import { ChildrenOption } from '../constant/children-option';

@Pipe({
  name: 'withChildren'
})
export class WithChildrenPipe implements PipeTransform {

  transform(code: number | undefined): string | undefined {
    if (!code) {
      return '';
    }
    return ChildrenOption.fromCode(code)?.name;
  }

}
