<div>
  <div mat-dialog-content>
    <span *ngIf="data.type === 'hashtag'">ハッシュタグ「#{{ data.tag }}」</span>
    <span *ngIf="data.type === 'tag'">タグ「@{{ data.tag }}」</span>
    を削除します。よろしいですか？  </div>
  <div class="mat-dialog-actions" mat-dialog-actions>
    <button mat-raised-button (click)="modalRef.close(false)">キャンセル</button>
    <button mat-raised-button color="warn" (click)="modalRef.close(true)">削除する</button>
  </div>
</div>
