import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Entry, Influencer } from '@cuepid/core';
import { Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { differenceInHours } from 'date-fns';

interface RecruitmentDetailComponentData {
  influencer: Influencer;
}

@Component({
  selector: 'app-recruitment-detail',
  templateUrl: './recruitment-detail.component.html',
  styleUrls: ['./recruitment-detail.component.scss'],
})
export class RecruitmentDetailComponent implements OnInit {
  dataSource: MatTableDataSource<Entry> = new MatTableDataSource();

  sort: Sort | null = null;

  readonly columns = ['status', 'duration', 'title'];

  constructor(
    private ref: MatDialogRef<RecruitmentDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecruitmentDetailComponentData
  ) {}

  ngOnInit(): void {
    this.dataSource.data = this.data.influencer.entries?.filter(
      (entry) => entry.accepted_at || entry.declined_at
    ) as Entry[];

    this.changeSort({
      active: 'duration',
      direction: 'asc',
    });
  }

  close(): void {
    this.ref.close();
  }

  changeSort(sort: Sort) {
    if (!sort.direction) {
      sort.direction = 'asc';
    }

    const entries = this.dataSource.data as [];
    entries.sort((a: any, b: any) => {
      let _a;
      let _b;
      switch (sort.active) {
        case 'status':
          _a = new Date(a['accepted_at']);
          _b = new Date(b['accepted_at']);
          break;
        case 'duration':
          _a = Math.abs(
            differenceInHours(
              new Date(a['campaign']['recruit_start_date']),
              new Date()
            )
          );
          _b = Math.abs(
            differenceInHours(
              new Date(b['campaign']['recruit_start_date']),
              new Date()
            )
          );
          break;
        case 'title':
          _a = a['campaign']['name'];
          _b = b['campaign']['name'];
          break;
      }
      if (_a < _b) {
        return sort.direction === 'asc' ? -1 : 1;
      }
      if (_a > _b) {
        return sort.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    const dataSource: MatTableDataSource<Entry> = new MatTableDataSource();

    dataSource.data = entries;
    this.dataSource = dataSource;
  }
}
