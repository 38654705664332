import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-plan-upgrade-toast]',
  templateUrl: './plan-upgrade-toast.component.html',
  styleUrls: ['./plan-upgrade-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}')
      )
    ])
  ],
  preserveWhitespaces: false
})
export class PlanUpgradeToastComponent extends Toast {

  constructor(service: ToastrService, toastPackage: ToastPackage, private readonly router: Router) {
    super(service, toastPackage);
  }

  moveToPlan(event: Event) {
    event.stopPropagation();
    this.remove();
    this.router.navigate(['/', 'plan']);
  }

}
