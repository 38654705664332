import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escapeHtmlTag'
})
export class EscapeHtmlTagPipe implements PipeTransform {

  transform(value: string | undefined): string | undefined {
    return value
      ?.replace(/</ug, '&lt;')
      ?.replace(/>/ug, '&gt;');
  }

}
