import { Component, Input, OnChanges } from '@angular/core';
import { InstagramInsightSnapshot } from '@cuepid/core';
import { Colors } from 'ng2-charts';

@Component({
  selector: 'app-instagram-public-relation-rate',
  templateUrl: './instagram-public-relation-rate.component.html',
  styleUrls: ['./instagram-public-relation-rate.component.scss'],
})
export class InstagramPublicRelationRateComponent implements OnChanges {
  @Input() snapshot?: InstagramInsightSnapshot;

  prMediaCount = 0;
  otherMediaCount = 0;
  total = 0;

  readonly colors: Colors[] = [{ backgroundColor: ['#EF94B3', '#6B629E'] }];
  readonly labels = ['PR広告', 'その他'];

  ngOnChanges(): void {
    const prMedia = this.snapshot?.pr_media;
    if (!prMedia) {
      return;
    }

    this.total = prMedia.total;
    this.prMediaCount = prMedia.pr_media_count;
    this.otherMediaCount = this.total - this.prMediaCount;
  }

  isReachedToRequirementCount() {
    return !!this.snapshot?.pr_media;
  }
}
