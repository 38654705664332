import { Injectable } from '@angular/core';

interface Condition {
  page: number;
  perPage: number;
  attribute: any;
}


@Injectable({
  providedIn: 'root'
})
export class ConditionStockService {

  influencer: Condition | null = null;

}
