<perfect-scrollbar [config]="{ wheelPropagation: false }">
  <table class="default-table" [dataSource]="addresses" mat-table>
    <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns" mat-row></tr>

    <ng-container matColumnDef="username">
      <th *matHeaderCellDef mat-header-cell>ユーザー名</th>
      <td *matCellDef="let row" mat-cell>{{ row.username }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell>氏名</th>
      <td *matCellDef="let row" mat-cell>{{ row.name }}</td>
    </ng-container>
    <ng-container matColumnDef="name_kana">
      <th *matHeaderCellDef mat-header-cell>カナ</th>
      <td *matCellDef="let row" mat-cell>{{ row.name_kana }}</td>
    </ng-container>
    <ng-container matColumnDef="postcode">
      <th *matHeaderCellDef mat-header-cell>郵便番号</th>
      <td *matCellDef="let row" mat-cell>{{ row.postcode }}</td>
    </ng-container>
    <ng-container matColumnDef="prefecture">
      <th *matHeaderCellDef mat-header-cell>都道府県</th>
      <td *matCellDef="let row" mat-cell>{{ row.prefecture }}</td>
    </ng-container>
    <ng-container matColumnDef="city">
      <th *matHeaderCellDef mat-header-cell>市区町村</th>
      <td *matCellDef="let row" mat-cell>{{ row.city }}</td>
    </ng-container>
    <ng-container matColumnDef="house_number">
      <th *matHeaderCellDef mat-header-cell>番地</th>
      <td *matCellDef="let row" mat-cell>{{ row.house_number }}</td>
    </ng-container>
    <ng-container matColumnDef="building">
      <th *matHeaderCellDef mat-header-cell>建物名等</th>
      <td *matCellDef="let row" mat-cell>{{ row.building }}</td>
    </ng-container>
    <ng-container matColumnDef="phone_number">
      <th *matHeaderCellDef mat-header-cell>電話番号</th>
      <td *matCellDef="let row" mat-cell>{{ row.phone_number }}</td>
    </ng-container>
  </table>
</perfect-scrollbar>

<section fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2rem">
  <button mat-raised-button color="close" mat-dialog-close>閉じる</button>
  <button mat-raised-button color="primary" (click)="copyToClipboard()">
    情報をコピーする
  </button>
</section>
