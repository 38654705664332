import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Influencer } from '@cuepid/core';
import { RecruitmentDetailComponent } from '@cuepid/enterprise/shared/components/influencer/recruitment-overview/dialogs/recruitment-detail/recruitment-detail.component';

@Component({
  selector: 'app-recruitment-overview',
  templateUrl: './recruitment-overview.component.html',
  styleUrls: ['./recruitment-overview.component.scss'],
})
export class RecruitmentOverviewComponent implements OnInit {
  @Input() influencer?: Influencer;
  @Input() disable = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  countAcceptedEntries(): number {
    return (
      this.influencer?.entries?.filter((entry) => !!entry.accepted_at).length ||
      0
    );
  }

  countDeclinedEntries(): number {
    return (
      this.influencer?.entries?.filter((entry) => !!entry.declined_at).length ||
      0
    );
  }

  showDetail(): void {
    if (this.disable) {
      return;
    }

    this.dialog.open(RecruitmentDetailComponent, {
      width: '1000px',
      height: '80vh',
      data: { influencer: this.influencer },
    });
  }
}
