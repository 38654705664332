import { Pipe, PipeTransform } from '@angular/core';
import { format, register } from 'timeago.js';
import locale from 'timeago.js/lib/lang/ja';

@Pipe({
  name: 'timeago'
})
export class TimeAgoPipe implements PipeTransform {

  constructor() {
    register('jp', locale);
  }

  transform(value: string | undefined): string {
    if (!value) {
      return '';
    }
    return format(value, 'jp');
  }

}
