import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { Company } from '@cuepid/core';
import {
  ILayoutConf,
  LayoutService,
  NavigationService,
  SidenavComponent,
} from '@cuepid/egret';
import { AuthService } from '@cuepid/enterprise/core/services/auth.service';
import { DirectMessageService } from '@cuepid/enterprise/core/services/direct-message.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { SessionService } from '@cuepid/enterprise/core/services/session.service';
import { AdministrationService } from '@cuepid/enterprise/core/services/administration.service';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
  styleUrls: ['./sidebar-side.component.scss'],
})
export class SidebarSideComponent implements OnInit, OnDestroy {
  @ViewChild('nav', { static: true }) nav!: SidenavComponent;

  menuItems: any[] = [];
  hasIconTypeMenuItem = false;
  iconTypeMenuTitle = '';
  layoutConf?: ILayoutConf;
  user?: Company;

  private menuItemsSub?: Subscription;
  private checkUnreadSub?: Subscription;

  constructor(
    private navService: NavigationService,
    private layout: LayoutService,
    private authService: AuthService,
    private directMessageService: DirectMessageService,
    private sessionService: SessionService,
    private administrationService: AdministrationService,
    router: Router
  ) {
    router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => /\/message\/\d+/.test(event.url))
      )
      .subscribe(() => this.closeUnreadBadge());
  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === 'icon'
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
    merge(this.authService.user(), this.authService.userUpdated).subscribe(
      (user) => (this.user = user)
    );

    if (!this.sessionService.initAdminLayout) {
      this.checkUnreadSub = this.directMessageService
        .hasUnreadMessage()
        .pipe(filter((exist) => exist))
        .subscribe(() => this.nav.badge.emit({ id: 'message', enable: true }));
      this.sessionService.initAdminLayout = true;
    }
  }

  ngOnDestroy() {
    this.menuItemsSub?.unsubscribe();
  }

  isInProxyLogin(): boolean {
    return this.administrationService.isInProxyLogin();
  }

  private closeUnreadBadge() {
    if (!!this.checkUnreadSub && !this.checkUnreadSub.closed) {
      this.checkUnreadSub?.unsubscribe();
    }
    this.nav.badge.emit({ id: 'message', enable: false });
  }
}
