import { routingInstrumentation } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

export const environment = {
  production: true,
  apiURL: 'https://enterprise.cuepid.net/api',
  googleMapApiKey: 'AIzaSyB5jjPFip-qt3xdKrRyJzdz2JX4TjJS-Tk',

  auth: {
    clientId: '4zbVjuxJQlQ36MNlvTvLpTyBazsXOWjH',
    domain: 'auth.cuepid.net',
    configurationBaseUrl: 'https://cuepid.jp.auth0.com',
    defaultDatabaseConnection: 'Companies',

    lockAuthOptions: {
      redirectUrl:
        'https://enterprise.cuepid.net/api/auth/company/signIn/callback',
      audience: 'https://api.cuepid.net',
    },
  },

  maintenance: {
    enabled: true,
    endpoint: 'https://cuepid-maintenance.microcms.io/api/v1/status',
    targetName: 'enterprise',
    apiKey: '2bda032f595c4ca789c12463e74b5f864ec3',
  },

  sentry: {
    enabled: true,
    debug: false,
    environment: 'production',
    dsn: 'https://0e008e376acd4b60838307ec4f779348@o206970.ingest.sentry.io/4969793',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['https://enterprise.cuepid.net'],
        routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.4,
  },
};
