<div [perfectScrollbar]="{}">
  <form [formGroup]="form" [ngClass]="{ readonly: readonly }">
    <div class="mb-8">
      <div class="mx-4 py-4">
        <label>投稿先のメディア</label>
      </div>
      <ng-container *ngIf="!readonly">
        <mat-button-toggle-group formControlName="medium_id" class="mb-24">
          <mat-button-toggle [value]="instagram.id"
            >Instagram</mat-button-toggle
          >
        </mat-button-toggle-group>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          <span *ngIf="form.get('medium_id')!.value === instagram.id"
            >Instagram</span
          >
        </div>
      </ng-container>
    </div>

    <div *ngIf="medium === instagram" formGroupName="instagram">
      <div class="mb-8">
        <div class="mx-4 py-4">
          <label>最低フォロワー数</label>
          <span
            class="cursor-pointer"
            fxFlexAlign="center"
            [matTooltip]="hints.minFollower"
            matTooltipPosition="right"
          >
            <mat-icon class="help">help</mat-icon>
          </span>
        </div>
        <ng-container *ngIf="!readonly">
          <mat-form-field appearance="outline">
            <input type="number" formControlName="min_follower" matInput />
            <mat-error
              *ngIf="form.hasError('required', 'instagram.min_follower')"
            >
              最低フォロワー数は必ずご入力下さい。
            </mat-error>
            <mat-error *ngIf="form.hasError('min', 'instagram.min_follower')">
              最低フォロワー数は100以上でご入力下さい。
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="readonly">
          <div class="mx-16 py-4">
            {{ form.get('instagram.min_follower')!.value }}
          </div>
        </ng-container>
      </div>
    </div>

    <div class="mb-8">
      <div class="mx-4 py-4">
        <label>投稿希望日</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.preferredDate"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>

      <ng-container *ngIf="!readonly">
        <mat-button-toggle-group formControlName="postType" class="mb-24">
          <mat-button-toggle *ngIf="type === eventType" [value]="null"
            >指定なし</mat-button-toggle
          >
          <mat-button-toggle [value]="toTerm.code">{{
            toTerm.name
          }}</mat-button-toggle>
          <mat-button-toggle [value]="toDay.code">{{
            toDay.name
          }}</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4 mb-8">
          <span *ngIf="form.get('postType')!.value === null">指定なし</span>
          <span *ngIf="form.get('postType')!.value === toTerm.code">{{
            toTerm.name
          }}</span>
          <span *ngIf="form.get('postType')!.value === toDay.code">{{
            toDay.name
          }}</span>
        </div>
      </ng-container>

      <div>
        <div
          *ngIf="postType === toTerm.code"
          class="full-width pb-8 post-term"
          [@switchPeriod]
        >
          <ng-container *ngIf="!readonly">
            <mat-form-field
              class="w-150"
              appearance="outline"
              (click)="beginning.open()"
            >
              <mat-label>開始日</mat-label>
              <input
                [matDatepicker]="beginning"
                formControlName="beginning"
                (dateChange)="setFormattedDate('beginning', $event.value)"
                matInput
                readonly
              />
              <mat-error>開始日を入力して下さい。</mat-error>
              <mat-datepicker-toggle
                matSuffix
                [for]="beginning"
              ></mat-datepicker-toggle>
              <mat-datepicker #beginning></mat-datepicker>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="readonly">
            <span class="mx-16">
              {{ form.get('beginning')!.value }}
            </span>
          </ng-container>
          <span class="px-8">〜</span>
          <ng-container *ngIf="!readonly">
            <mat-form-field
              class="w-150"
              appearance="outline"
              (click)="deadline.open()"
            >
              <mat-label>終了日</mat-label>
              <input
                [matDatepicker]="deadline"
                formControlName="deadline"
                (dateChange)="setFormattedDate('deadline', $event.value)"
                matInput
                readonly
              />
              <mat-error>終了日を入力して下さい。</mat-error>
              <mat-datepicker-toggle
                matSuffix
                [for]="deadline"
              ></mat-datepicker-toggle>
              <mat-datepicker #deadline></mat-datepicker>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="readonly">
            <span class="mx-16">
              {{ form.get('deadline')!.value }}
            </span>
          </ng-container>
        </div>
        <div
          *ngIf="postType === toDay.code"
          class="full-width pb-8 grace-days"
          [@switchPeriod]
        >
          <ng-container *ngIf="!readonly">
            {{ type?.code === 0 ? 'イベント参加から' : '商品発送から' }}
            <mat-form-field appearance="outline">
              <input formControlName="grace_days" matInput />
              <mat-error *ngIf="form.hasError('requiredDays', 'grace_days')"
                >日数を入力して下さい。</mat-error
              >
              <mat-error *ngIf="form.hasError('max', 'grace_days')"
                >120日以下で入力して下さい。</mat-error
              >
            </mat-form-field>
            日以内
          </ng-container>
          <ng-container *ngIf="readonly">
            <div class="mx-16 py-4">
              {{ type?.code === 0 ? 'イベント参加から' : '商品発送から'
              }}{{ form.get('grace_days')!.value }}日以内
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="mb-8">
      <div class="mx-4 py-4">
        <label>投稿内容の2次利用</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.secondaryUse"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>
      <ng-container *ngIf="!readonly">
        <mat-button-toggle-group formControlName="secondary_use" class="mb-24">
          <mat-button-toggle [value]="true">予定あり</mat-button-toggle>
          <mat-button-toggle [value]="false">しない</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          {{ form.get('secondary_use')!.value ? '予定あり' : 'しない' }}
        </div>
      </ng-container>
    </div>

    <div *ngIf="medium === instagram" formGroupName="instagram">
      <div class="mb-8">
        <div class="mx-4 py-4">
          <label>投稿に必須のハッシュタグを指定</label>
          <span
            class="cursor-pointer"
            fxFlexAlign="center"
            [matTooltip]="hints.hashTags"
            matTooltipPosition="right"
          >
            <mat-icon class="help">help</mat-icon>
          </span>
        </div>
        <div
          *ngFor="let formControl of getTagsInputControls(); index as i"
          class="inline"
        >
          <ng-container *ngIf="!readonly">
            <mat-form-field appearance="outline" class="mr-16">
              <div matPrefix>#</div>
              <input [formControl]="formControl" matInput />
              <mat-icon matSuffix (click)="removeTag(i)" class="remove-tag"
                >close</mat-icon
              >
              <mat-error>ハッシュタグには#を含めずご入力ください</mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="readonly">
            <span class="ml-16 my-4 i-block">#{{ formControl.value }}</span>
          </ng-container>
        </div>
        <a
          (click)="addTagsInput()"
          class="action-link-text link-add-tag"
          *ngIf="!readonly"
          >入力欄を追加する</a
        >
      </div>
    </div>

    <div class="mb-8">
      <div class="mx-4 py-4">
        <label>投稿の手順・流れ</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.postingProcedure"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>

      <table class="input-grid">
        <tr>
          <td>顔出し</td>
          <td>
            <ng-container *ngIf="!readonly">
              <mat-button-toggle-group formControlName="show_ones_face">
                <mat-button-toggle [value]="true">必須</mat-button-toggle>
                <mat-button-toggle [value]="false">任意</mat-button-toggle>
              </mat-button-toggle-group>
            </ng-container>
            <ng-container *ngIf="readonly">
              <div class="mx-16 py-4">
                {{ form.get('show_ones_face')!.value ? '必須' : '任意' }}
              </div>
            </ng-container>
          </td>
        </tr>
        <tr formGroupName="instagram">
          <td class="partnership">タイアップタグ</td>
          <td>
            <ng-container *ngIf="!readonly">
              <mat-button-toggle-group
                formControlName="required_partnership_tags"
              >
                <mat-button-toggle [value]="true">必須</mat-button-toggle>
                <mat-button-toggle [value]="false">任意</mat-button-toggle>
              </mat-button-toggle-group>
            </ng-container>
            <ng-container *ngIf="readonly">
              <div class="mx-16 py-4">
                {{
                  form.get('instagram.required_partnership_tags')!.value
                    ? '必須'
                    : '任意'
                }}
              </div>
            </ng-container>
            <div formArrayName="partnership_tags">
              <div
                *ngFor="
                  let formControl of getTagsInputs('instagram.partnership_tags')
                    .controls;
                  index as i
                "
                class="inline"
              >
                <ng-container *ngIf="!readonly">
                  <mat-form-field appearance="outline" class="mr-16">
                    <div matPrefix>@</div>
                    <input [formControlName]="i" matInput />
                    <mat-icon
                      matSuffix
                      *ngIf="canRemoveTag('instagram.partnership_tags')"
                      (click)="removeTag(i, 'instagram.partnership_tags')"
                      class="remove-tag"
                      >close</mat-icon
                    >
                    <mat-error>タグには@を含めずご入力ください</mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="readonly">
                  <span class="ml-16 my-4 i-block"
                    >@{{ formControl.value }}</span
                  >
                </ng-container>
              </div>
              <a
                (click)="addTagsInput('', 'instagram.partnership_tags')"
                class="action-link-text link-add-tag"
                *ngIf="!readonly"
                >入力欄を追加する</a
              >
            </div>
            <div *ngIf="!readonly" class="partnership description">
              タイアップタグとは、Instagramユーザーが企業の商品サービスを何かしらの対価を受けて紹介する際に、
              当投稿がPR目的の投稿で あるということを示すタグになります。<br />
              meta社が定めているブランドコンテンツポリシーは<a
                href="https://help.instagram.com/616901995832907"
                target="_blank"
                rel="noreferrer"
                >こちら</a
              >
            </div>
          </td>
        </tr>
        <tr>
          <td>投稿画像へのタグ付け</td>
          <td>
            <ng-container *ngIf="!readonly">
              <mat-button-toggle-group formControlName="tagging_into_post">
                <mat-button-toggle [value]="true">必須</mat-button-toggle>
                <mat-button-toggle [value]="false">任意</mat-button-toggle>
              </mat-button-toggle-group>
            </ng-container>
            <ng-container *ngIf="readonly">
              <div class="mx-16 py-4">
                {{ form.get('tagging_into_post')!.value ? '必須' : '任意' }}
              </div>
            </ng-container>
            <div>
              <div
                *ngFor="
                  let formControl of getTagsInputControls(
                    'instagram.tags_in_image'
                  );
                  index as i
                "
                class="inline"
              >
                <ng-container *ngIf="!readonly">
                  <mat-form-field appearance="outline" class="mr-16">
                    <div matPrefix>@</div>
                    <input [formControl]="formControl" matInput />
                    <mat-icon
                      matSuffix
                      *ngIf="canRemoveTag('instagram.tags_in_image')"
                      (click)="removeTag(i, 'instagram.tags_in_image')"
                      class="remove-tag"
                      >close</mat-icon
                    >
                    <mat-error>タグには@を含めずご入力ください</mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="readonly">
                  <span class="ml-16 my-4 i-block"
                    >@{{ formControl.value }}</span
                  >
                </ng-container>
              </div>
              <a
                (click)="addTagsInput('', 'instagram.tags_in_image')"
                class="action-link-text link-add-tag"
                *ngIf="!readonly"
                >入力欄を追加する</a
              >
            </div>
          </td>
        </tr>
        <tr>
          <td>投稿文章内へのアカウント名記載</td>
          <td>
            <ng-container *ngIf="!readonly">
              <mat-button-toggle-group
                formControlName="include_account_name_in_post_caption"
              >
                <mat-button-toggle [value]="true">必須</mat-button-toggle>
                <mat-button-toggle [value]="false">任意</mat-button-toggle>
              </mat-button-toggle-group>
            </ng-container>
            <ng-container *ngIf="readonly">
              <div class="mx-16 py-4">
                {{
                  form.get('include_account_name_in_post_caption')!.value
                    ? '必須'
                    : '任意'
                }}
              </div>
            </ng-container>
            <div>
              <div
                *ngFor="
                  let formControl of getTagsInputControls(
                    'instagram.tags_in_caption'
                  );
                  index as i
                "
                class="inline"
              >
                <ng-container *ngIf="!readonly">
                  <mat-form-field appearance="outline" class="mr-16">
                    <div matPrefix>@</div>
                    <input [formControl]="formControl" matInput />
                    <mat-icon
                      matSuffix
                      *ngIf="canRemoveTag('instagram.tags_in_caption')"
                      (click)="removeTag(i, 'instagram.tags_in_caption')"
                      class="remove-tag"
                      >close</mat-icon
                    >
                    <mat-error>タグには@を含めずご入力ください</mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="readonly">
                  <span class="ml-16 my-4 i-block"
                    >@{{ formControl.value }}</span
                  >
                </ng-container>
              </div>
              <a
                (click)="addTagsInput('', 'instagram.tags_in_caption')"
                class="action-link-text link-add-tag"
                *ngIf="!readonly"
                >入力欄を追加する</a
              >
            </div>
          </td>
        </tr>
        <tr>
          <td>その他記載事項</td>
          <td>
            <ng-container *ngIf="!readonly">
              <span
                class="action-link-text right"
                (click)="
                  openTemplateList(
                    form.get('other_notes_for_posting_procedure')!,
                    other_notes_for_posting_procedure,
                    'otherNotesForPostingProcedure'
                  )
                "
                >テンプレート挿入</span
              >
              <mat-form-field class="full-width" appearance="outline">
                <textarea
                  formControlName="other_notes_for_posting_procedure"
                  matInput
                  placeholder="入力して下さい"
                  #other_notes_for_posting_procedure
                ></textarea>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="readonly">
              <div class="mx-16 py-4">
                <div
                  [innerHTML]="
                    form.get('other_notes_for_posting_procedure')!.value
                      | escapeHtmlTag
                      | newLine
                      | safeHtml
                  "
                ></div>
              </div>
            </ng-container>
          </td>
        </tr>
      </table>
    </div>

    <div class="mb-8">
      <div class="mx-4 py-4 inline">
        <label>採用後の流れ</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.flowAfterHiring"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>
      <ng-container *ngIf="!readonly">
        <span
          class="action-link-text right"
          (click)="
            openTemplateList(
              form.get('flow_after_hiring')!,
              flow_after_hiring,
              'flowAfterHiring'
            )
          "
          >テンプレート挿入</span
        >
        <mat-form-field class="full-width mt-4" appearance="outline">
          <textarea
            formControlName="flow_after_hiring"
            matInput
            placeholder="入力して下さい"
            #flow_after_hiring
          ></textarea>
          <mat-error>採用後の流れは必ず入力してください</mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          <div
            [innerHTML]="
              form.get('flow_after_hiring')!.value
                | escapeHtmlTag
                | newLine
                | safeHtml
            "
          ></div>
        </div>
      </ng-container>
    </div>

    <div class="mb-8">
      <div class="mx-4 py-4 inline">
        <label>投稿に関しての備考・注意事項</label>
        <span
          class="cursor-pointer"
          fxFlexAlign="center"
          [matTooltip]="hints.note"
          matTooltipPosition="right"
        >
          <mat-icon class="help">help</mat-icon>
        </span>
      </div>
      <ng-container *ngIf="!readonly">
        <span
          class="action-link-text right"
          (click)="openTemplateList(form.get('note')!, note, 'note')"
          >テンプレート挿入</span
        >
        <mat-form-field class="full-width mt-4" appearance="outline">
          <textarea
            formControlName="note"
            matInput
            placeholder="入力して下さい"
            #note
          ></textarea>
          <mat-error>備考・注意事項は必ず入力してください</mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="readonly">
        <div class="mx-16 py-4">
          <div
            [innerHTML]="
              form.get('note')!.value | escapeHtmlTag | newLine | safeHtml
            "
          ></div>
        </div>
      </ng-container>
    </div>
  </form>
</div>
