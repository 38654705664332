import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { WINDOW } from '@cuepid/core';
import { MessageTemplateService } from '@cuepid/enterprise/core/services/message-template.service';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';

@Component({
  selector: 'app-input-message-template-title-dialog',
  templateUrl: './input-message-template-title-dialog.component.html',
  styleUrls: ['./input-message-template-title-dialog.component.scss']
})
export class InputMessageTemplateTitleDialogComponent implements OnInit {

  templateNameControl: FormControl;

  constructor(fb: FormBuilder, private readonly ref: MatDialogRef<InputMessageTemplateTitleDialogComponent>,
              private readonly toastrService: ToastrService, private readonly messageTemplateService: MessageTemplateService,
              @Inject(WINDOW) private readonly window: Window, @Inject(MAT_DIALOG_DATA) private readonly messageBody: string) {
    this.templateNameControl = fb.control('', [Validators.required, Validators.maxLength(30)]);
  }

  ngOnInit(): void {
    if (!this.messageBody || !this.messageBody?.trim()) {
      this.ref.close();
      return;
    }
  }

  saveTemplate(): void {
    if (this.templateNameControl.invalid) {
      this.toastrService.warning('赤枠で囲われている箇所を修正してください。', '入力エラーがあります');
      return;
    }

    const confirmed = this.window.confirm('入力された内容でテンプレートを作成します。よろしいですか？');
    if (!confirmed) {
      return;
    }

    this.messageTemplateService.create({
      name: this.templateNameControl.value,
      body: this.messageBody,
    }).pipe(
      catchError(err => {
        if (!(err instanceof HttpErrorResponse) || err.status !== 422) {
          return throwError(err);
        }
        Object.values(err.error.errors as string[])
          .flatMap(value => value)
          .forEach((message: string) => this.toastrService.error(message));
        return EMPTY;
      }),
      tap(() => this.toastrService.info('テンプレートを保存しました。')),
    ).subscribe(() => this.ref.close());
  }

  close() {
    this.ref.close();
  }
}
