<form [formGroup]="form" [ngClass]="{ readonly: readonly }">
  <div class="mb-8" formGroupName="features">
    <div class="mx-4 py-4">
      <label>
        <span *ngIf="type?.code === 0">イベント</span>
        <span *ngIf="type?.code === 1">商品</span>
        紹介・特徴
      </label>
      <span
        class="cursor-pointer"
        fxFlexAlign="center"
        [matTooltip]="hints.features"
        matTooltipPosition="right"
      >
        <mat-icon class="help">help</mat-icon>
      </span>
    </div>

    <div
      fxLayout="row"
      *ngFor="let control of getControls(form, 'features')"
      [formGroupName]="getControlName(control)"
    >
      <div
        class="left"
        fxFlex="1 1 auto"
        *ngIf="!readonly && getControls(form, 'features').length > 1"
      >
        <div fxLayout="column" fxLayoutAlign="space-between" class="h-100p">
          <div
            class="action-icon-wrapper h-100p"
            fxFlex="0 0 50px"
            (click)="moveToPrev('feature', getControlName(control))"
            *ngIf="hasPrev('feature', getControlName(control))"
          >
            <div fxLayoutAlign="center center" class="h-100p">
              <mat-icon>arrow_upward</mat-icon>
            </div>
          </div>
          <div
            class="action-icon-wrapper h-100p"
            fxFlex="grow"
            (click)="deleteFeature('feature', getControlName(control))"
          >
            <div fxLayoutAlign="center center" class="h-100p">
              <mat-icon>remove_circle_outline</mat-icon>
            </div>
          </div>
          <div
            class="action-icon-wrapper h-100p"
            fxFlex="0 0 50px"
            (click)="moveToNext('feature', getControlName(control))"
            *ngIf="hasNext('feature', getControlName(control))"
          >
            <div fxLayoutAlign="center center" class="h-100p">
              <mat-icon>arrow_downward</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="center" fxFlex="18 1 auto">
        <ng-container *ngIf="!readonly">
          <span
            class="action-link-text right"
            (click)="openTemplateList(control, textarea, 'feature')"
            >テンプレート挿入</span
          >
          <mat-form-field class="full-width" appearance="outline">
            <input
              formControlName="title"
              matInput
              placeholder="タイトルを入力して下さい"
            />
            <mat-error>タイトルは必ず入力してください</mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <textarea
              formControlName="body"
              matInput
              placeholder="本文を入力して下さい"
              #textarea
            ></textarea>
            <mat-error>本文は必ず入力してください</mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="readonly">
          <table class="preview">
            <tr>
              <td>タイトル</td>
              <td>{{ control.get('title')!.value }}</td>
            </tr>
            <tr>
              <td>本文</td>
              <td>
                <div
                  [innerHTML]="
                    control.get('body')!.value
                      | escapeHtmlTag
                      | newLine
                      | safeHtml
                  "
                ></div>
              </td>
            </tr>
            <tr *ngIf="resolvePreviewImage(control)">
              <td>画像</td>
              <td>
                <div class="preview-image">
                  <img [src]="resolvePreviewImage(control)! | safeUrl" alt="" />
                </div>
              </td>
            </tr>
          </table>
        </ng-container>
      </div>
      <div class="right" fxFlex="6 1 auto" *ngIf="!readonly">
        <div fxLayout="column" class="h-100p">
          <div class="h-100p">
            <div
              class="file-input-wrapper"
              fxLayoutAlign="center center"
              fxLayout="column"
            >
              <input
                type="file"
                style="display: none"
                accept="image/*"
                #fileInput
                (change)="onImageSelect($event, control)"
              />
              <div fxLayout="row">
                <button
                  type="button"
                  mat-raised-button
                  color="primary"
                  class="file-select-button"
                  (click)="
                    fileInput.click(); control.get('image')!.markAsTouched()
                  "
                >
                  <mat-icon>attach_file</mat-icon>
                  ファイルを選択
                </button>
                <button
                  type="button"
                  mat-raised-button
                  class="file-delete-button"
                  (click)="deleteImage(control)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
              <div class="preview-image" *ngIf="resolvePreviewImage(control)">
                <img [src]="resolvePreviewImage(control)! | safeUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center" *ngIf="!readonly">
      <button
        type="button"
        mat-mini-fab
        color="primary"
        (click)="addFeatures('feature')"
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
  </div>

  <div class="mb-8" formGroupName="philosophies">
    <div class="mx-4 py-4">
      <label>商品誕生の背景や想い</label>
      <span
        class="cursor-pointer"
        fxFlexAlign="center"
        [matTooltip]="hints.philosophy"
        matTooltipPosition="right"
      >
        <mat-icon class="help">help</mat-icon>
      </span>
    </div>

    <div
      fxLayout="row"
      *ngFor="let control of getControls(form, 'philosophies')"
      [formGroupName]="getControlName(control)"
    >
      <div
        class="left"
        fxFlex="1 1 auto"
        *ngIf="!readonly && getControls(form, 'philosophies').length > 1"
      >
        <div fxLayout="column" fxLayoutAlign="space-between" class="h-100p">
          <div
            class="action-icon-wrapper h-100p"
            fxFlex="0 0 50px"
            (click)="moveToPrev('philosophy', getControlName(control))"
            *ngIf="hasPrev('philosophy', getControlName(control))"
          >
            <div fxLayoutAlign="center center" class="h-100p">
              <mat-icon>arrow_upward</mat-icon>
            </div>
          </div>
          <div
            class="action-icon-wrapper h-100p"
            fxFlex="grow"
            (click)="deleteFeature('philosophy', getControlName(control))"
          >
            <div fxLayoutAlign="center center" class="h-100p">
              <mat-icon>remove_circle_outline</mat-icon>
            </div>
          </div>
          <div
            class="action-icon-wrapper h-100p"
            fxFlex="0 0 50px"
            (click)="moveToNext('philosophy', getControlName(control))"
            *ngIf="hasNext('philosophy', getControlName(control))"
          >
            <div fxLayoutAlign="center center" class="h-100p">
              <mat-icon>arrow_downward</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="center" fxFlex="18 1 auto">
        <ng-container *ngIf="!readonly">
          <span
            class="action-link-text right"
            (click)="openTemplateList(control, textarea, 'philosophy')"
            >テンプレート挿入</span
          >
          <mat-form-field class="full-width" appearance="outline">
            <input
              formControlName="title"
              matInput
              placeholder="タイトルを入力して下さい"
            />
            <mat-error>タイトルは必ず入力してください</mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <textarea
              formControlName="body"
              matInput
              placeholder="本文を入力して下さい"
              #textarea
            ></textarea>
            <mat-error>本文は必ず入力してください</mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="readonly">
          <table class="preview">
            <tr>
              <td>タイトル</td>
              <td>{{ control.get('title')!.value }}</td>
            </tr>
            <tr>
              <td>本文</td>
              <td>
                <div
                  [innerHTML]="
                    control.get('body')!.value
                      | escapeHtmlTag
                      | newLine
                      | safeHtml
                  "
                ></div>
              </td>
            </tr>
            <tr *ngIf="resolvePreviewImage(control)">
              <td>画像</td>
              <td>
                <div class="preview-image">
                  <img [src]="resolvePreviewImage(control)! | safeUrl" alt="" />
                </div>
              </td>
            </tr>
          </table>
        </ng-container>
      </div>
      <div class="right" fxFlex="6 1 auto" *ngIf="!readonly">
        <div fxLayout="column" class="h-100p">
          <div class="h-100p">
            <div
              class="file-input-wrapper"
              fxLayoutAlign="center center"
              fxLayout="column"
            >
              <input
                type="file"
                style="display: none"
                accept="image/*"
                #fileInput
                (change)="onImageSelect($event, control)"
              />
              <div fxLayout="row">
                <button
                  type="button"
                  mat-raised-button
                  color="primary"
                  class="file-select-button"
                  (click)="
                    fileInput.click(); control.get('image')!.markAsTouched()
                  "
                >
                  <mat-icon>attach_file</mat-icon>
                  ファイルを選択
                </button>
                <button
                  type="button"
                  mat-raised-button
                  class="file-delete-button"
                  (click)="deleteImage(control)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
              <div class="preview-image" *ngIf="resolvePreviewImage(control)">
                <img [src]="resolvePreviewImage(control)! | safeUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center" *ngIf="!readonly">
      <button
        type="button"
        mat-mini-fab
        color="primary"
        (click)="addFeatures('philosophy')"
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
  </div>

  <div class="mx-4 py-4">
    <label class="larger">会社紹介</label>
    <small *ngIf="!readonly"
      ><a class="underline" (click)="moveToProfileOverview()"
        >設定 > 企業プロフィール</a
      >の中身が自動挿入されています</small
    >
  </div>

  <div class="mb-8">
    <div class="mx-4 py-4">
      <label>企業説明文</label>
      <span
        class="action-link-text right"
        (click)="updateCompanyProfile('introduction')"
        *ngIf="!readonly"
        >この内容で企業プロフィールを更新</span
      >
    </div>
    <ng-container *ngIf="!readonly">
      <mat-form-field class="full-width mt-4" appearance="outline">
        <textarea
          formControlName="company_introduction"
          matInput
          placeholder="企業説明文を入力して下さい"
        ></textarea>
        <mat-error>企業説明文は必ず入力してください</mat-error>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="readonly">
      <div class="mx-16 py-4">
        <div
          [innerHTML]="
            form.get('company_introduction')!.value
              | escapeHtmlTag
              | newLine
              | safeHtml
          "
        ></div>
      </div>
    </ng-container>
  </div>

  <div class="mb-0">
    <div class="mx-4 py-4">
      <label>企業理念や想い</label>
      <span
        class="action-link-text right"
        (click)="updateCompanyProfile('philosophy')"
        *ngIf="!readonly"
        >この内容で企業プロフィールを更新</span
      >
    </div>
    <ng-container *ngIf="!readonly">
      <mat-form-field class="full-width mt-4" appearance="outline">
        <textarea
          formControlName="company_philosophy"
          matInput
          placeholder="企業理念や想いを入力して下さい"
        ></textarea>
        <mat-error>企業理念や想いは必ず入力してください</mat-error>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="readonly">
      <div class="mx-16 py-4">
        <div
          [innerHTML]="
            form.get('company_philosophy')!.value
              | escapeHtmlTag
              | newLine
              | safeHtml
          "
        ></div>
      </div>
    </ng-container>
  </div>

  <div class="mb-8">
    <small class="pl-0" *ngIf="!readonly"
      >※上記に加えて、アイコンや企業名などの基本情報はデフォルトで出力されます</small
    >
  </div>
</form>
