import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL, Company, UseApplication } from '@cuepid/core';
import { Observable } from 'rxjs';
import { environment } from '@cuepid/enterprise/env';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apiBaseUrl: string
  ) {}

  sendApplicationForUse(company: UseApplication): Observable<any> {
    return this.http.post(`${environment.apiURL}/company/application`, company);
  }

  sendAgreement(): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/company/me/agreement/send`, {});
  }

  update(key: string, value: any): Observable<Company> {
    return this.http.patch<Company>(`${this.apiBaseUrl}/company/me`, {
      [key]: value,
    });
  }

  updateIcon(icon: Blob): Observable<Company> {
    const form = new FormData();
    form.append('icon_image', icon);
    form.append('_method', 'PATCH');
    return this.http.post<Company>(`${this.apiBaseUrl}/company/me`, form);
  }

  createViewHistory(influencerId: number): Observable<any> {
    return this.http.post(
      `${this.apiBaseUrl}/company/me/influencer/${influencerId}/viewed`,
      {}
    );
  }

  influencerWasViewed(influencerId: number): Observable<any> {
    return this.http.get(
      `${this.apiBaseUrl}/company/me/influencer/${influencerId}/viewed`
    );
  }
}
