import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedServicesModule } from './services/shared-services.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { AppLoaderComponent } from './components/app-loader/app-loader.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MatIconModule } from '@angular/material/icon';
import { CuepidCoreModule } from '@cuepid/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { FlexModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  imports: [
    CommonModule,
    SharedDirectivesModule,
    SharedPipesModule,
    SharedServicesModule,
    RouterModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CuepidCoreModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,
    MatRippleModule,
    FlexModule,
    MatBadgeModule,
  ],
  declarations: [
    AppLoaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    SidenavComponent,
  ],
  exports: [
    AppLoaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    SidenavComponent,
  ]
})
export class EgretModule {
}
