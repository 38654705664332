import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ThemeService } from './theme.service';
import { WINDOW } from '@cuepid/core';

export interface ILayoutConf {
  navigationPos?: string; // side, top
  sidebarStyle?: string; // full, compact, closed
  sidebarCompactToggle?: boolean; // sidebar expandable on hover
  sidebarColor?: string; // Sidebar background color http://demos.ui-lib.com/egret-doc/#egret-colors
  dir?: string; // ltr, rtl
  isMobile?: boolean; // updated automatically
  useBreadcrumb?: boolean; // Breadcrumb enabled/disabled
  breadcrumb?: string; // simple, title
  topbarFixed?: boolean; // Fixed header
  footerFixed?: boolean; // Fixed Footer
  topbarColor?: string; // Header background color http://demos.ui-lib.com/egret-doc/#egret-colors
  footerColor?: string // Header background color http://demos.ui-lib.com/egret-doc/#egret-colors
  matTheme?: string; // material theme. egret-blue, egret-navy, egret-dark-purple, egret-dark-pink
  perfectScrollbar?: boolean;
}
export const LAYOUT_CONFIG = new InjectionToken<ILayoutConf>('LAYOUT_CONFIG');

export interface ILayoutChangeOptions {
  duration?: number;
  transitionClass?: boolean;
}

interface IAdjustScreenOptions {
  browserEvent?: any;
  route?: string;
}

@Injectable()
export class LayoutService {
  layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
  layoutConf$ = this.layoutConfSubject.asObservable();
  public isMobile = false;
  public currentRoute = '';
  public fullWidthRoutes = ['shop'];

  constructor(private themeService: ThemeService, @Inject(LAYOUT_CONFIG) public layoutConf: ILayoutConf,
              @Inject(WINDOW) private window: Window) {
    this.setAppLayout(layoutConf);
  }

  setAppLayout(layoutConf: ILayoutConf) {
    this.layoutConf = {...this.layoutConf, ...layoutConf};
    this.applyMatTheme(this.layoutConf.matTheme);
  }

  publishLayoutChange(lc: ILayoutConf, opt: ILayoutChangeOptions = {}) {
    if (this.layoutConf.matTheme !== lc.matTheme && lc.matTheme) {
      this.themeService.changeTheme(this.layoutConf.matTheme, lc.matTheme);
    }

    this.layoutConf = Object.assign(this.layoutConf, lc);
    this.layoutConfSubject.next(this.layoutConf);
  }

  applyMatTheme(theme = this.layoutConf.matTheme) {
    if (!theme) {
      return;
    }
    this.themeService.applyMatTheme(theme);
  }

  adjustLayout(options: IAdjustScreenOptions | Event = {}) {
    let sidebarStyle: string;
    this.isMobile = this.isSm();
    if ('route' in options && !!options.route) {
      this.currentRoute = options.route;
    }
    sidebarStyle = this.isMobile ? 'closed' : 'full';

    if (this.currentRoute) {
      this.fullWidthRoutes.forEach(route => {
        if (this.currentRoute.indexOf(route) !== -1) {
          sidebarStyle = 'closed';
        }
      });
    }

    this.publishLayoutChange({
      isMobile: this.isMobile,
      sidebarStyle
    });
  }

  isSm() {
    return this.window.matchMedia(`(max-width: 959px)`).matches;
  }
}
