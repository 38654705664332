<mat-card>
  <mat-card-header>
    <mat-card-title>エリア</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <span fxLayoutAlign="center" *ngIf="!city">データが存在しません</span>
    <ng-container *ngIf="!!city">
      <div *ngFor="let area of city?.areas | orderBy:['-value', 'name'] | slice:0:5"
           fxLayout="row">
        <span fxFlex="4rem" fxLayoutAlign="center">{{ area.name }}</span>
        <mat-progress-bar fxFlex="1fr" mode="determinate" [value]="area.value / sum * 100"></mat-progress-bar>
        <span fxFlex="3rem" fxLayoutAlign="center">{{ area.value / sum | percent:'1.1' }}</span>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
