import { Pipe, PipeTransform } from '@angular/core';
import { Prefecture } from '../constant/prefecture';

@Pipe({
  name: 'prefecture'
})
export class PrefecturePipe implements PipeTransform {

  transform(code: number | undefined): string | undefined {
    if (!code) {
      return '';
    }
    return Prefecture.fromCode(code)?.name;
  }

}
