<mat-card>
  <mat-card-header>
    <mat-card-title>PR比率</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <span *ngIf="!isReachedToRequirementCount()" fxLayoutAlign="center"
      >計測できませんでした</span
    >
    <ng-container *ngIf="isReachedToRequirementCount()">
      <canvas
        baseChart
        chartType="doughnut"
        [data]="[prMediaCount, otherMediaCount]"
        [labels]="labels"
        [colors]="colors"
        [legend]="false"
      ></canvas>
      <div class="data mt-12" gdAuto gdColumns="4.5rem minmax(5rem auto) 4rem">
        <div gdRow="1" gdColumn="1"><span class="marker pr"></span> PR広告</div>
        <div gdRow="1" gdColumn="2" gdGridAlign="end">
          {{ prMediaCount | number }} 件
        </div>
        <div gdRow="1" gdColumn="3" gdGridAlign="end">
          {{ prMediaCount / total | percent: '1.1' }}
        </div>

        <div gdRow="2" gdColumn="1">
          <span class="marker non-pr"></span> その他
        </div>
        <div gdRow="2" gdColumn="2" gdGridAlign="end">
          {{ otherMediaCount | number }} 件
        </div>
        <div gdRow="2" gdColumn="3" gdGridAlign="end">
          {{ otherMediaCount / total | percent: '1.1' }}
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
