import { Component, Input } from '@angular/core';
import { InstagramAccount, InstagramPerformance } from '@cuepid/core';

@Component({
  selector: 'app-instagram-account-basic-info',
  templateUrl: './instagram-account-basic-info.component.html',
  styleUrls: ['./instagram-account-basic-info.component.scss'],
})
export class InstagramAccountBasicInfoComponent {

  @Input() account?: InstagramAccount;

  get performance(): InstagramPerformance | undefined | null {
    return this.account?.latest_performance;
  }

  get engagement(): number {
    if (!this.performance || !this.performance.average_impression) {
      return 0;
    }

    return (this.performance.average_like + this.performance.average_comment + this.performance.average_saved)
      / this.performance.average_impression
  }
}
